import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '@material-ui/core';
import ActivityCountComponent from './activityCount';
import styled from 'styled-components';

import { useActivityState } from 'stores/activityStore';

import UnsuccessfulScanIcon from 'assets/images/pngs/whiteIcons/whitebag-x.png';
import SuccessfulScanIcon from 'assets/images/pngs/whiteIcons/whitebag-check.png';

const BagActivityCount: React.FC = observer(() => {
  const { scannedBagsActivity, activityCleanup } = useActivityState();

  const successScans = scannedBagsActivity.successfulBagScans.length;
  const failedScans = scannedBagsActivity.unsuccessfulBagScans.length;

  const Icons = [
    { src: UnsuccessfulScanIcon, count: failedScans, width: '60px', height: '60px' },
    { src: SuccessfulScanIcon, count: successScans, width: '60px', height: '60px' },
  ];

  return (
    <ActivityCountComponent renderIcons={Icons}>
      <WrapperDiv>
        <TitleWrapper>Bags</TitleWrapper>
        <ContentWrapper>
          <div>Not Scanned Successfully</div>
          <div>{failedScans}</div>
        </ContentWrapper>
        <ContentWrapper>
          <div>Scanned</div>
          <div>{successScans}</div>
        </ContentWrapper>

        <FlexButton>
          <StyledResetButton onClick={activityCleanup} variant="outlined">
            Reset
          </StyledResetButton>
        </FlexButton>
      </WrapperDiv>
    </ActivityCountComponent>
  );
});

export default BagActivityCount;

const WrapperDiv = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 15rem;
  padding: 1rem;
  align-items: stretch;
  justify-content: space-between;
`;
const TitleWrapper = styled.div`
  font-size: 1.2rem;
  margin: 0.5rem 0;
  padding-bottom: 0.3rem;
`;
const ContentWrapper = styled.div`
  font-size: 1.2rem;
  margin: 0.5rem 0;
  padding-bottom: 0.3rem;
  display: flex;
  justify-content: space-between;
`;

const FlexButton = styled.div`
  align-self: flex-end;
  margin-right: 1rem;
`;

const StyledResetButton = styled(Button)`
  color: red;
  border-color: red;
  font-weight: 400;
`;
