import Axios, { AxiosRequestConfig } from 'axios';

import Axious from './api';
import { IOrderBag, IOrderItemResponse, IBrandsResponse, DetailsList, IOrderItem } from '../utils/interfaces/stationInterfaces';
import { FoldingType } from 'utils/enums/stationEnums';

class StationService {
  /**
   * @param qrCode bag qr code
   * @returns Bag Details if found
   */
  public async getOrderBagByQrCode(qrCode: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/bags/${qrCode}`,
    };

    const response = await Axious.request<IOrderBag>(requestOptions);
    return response.data.bag;
  }

  /**
   * Get Item by RFID
   * @param rfidCode - string
   * @returns {IOrderItemResponse} - Item Object
   */
  public async getOrderItemByRfidCode(rfidCode: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/items/rfid/${rfidCode}`,
    };

    const response = await Axious.request<IOrderItemResponse>(requestOptions);
    return response.data.item;
  }

  public async getOrderItemByQrCode(qrCode: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/items/${qrCode}`,
    };
    const response = await Axious.request<IOrderItemResponse>(requestOptions);
    return response.data.item;
  }

  /**
   * Call the add-new-to-bag API
   * @param itemCode - string
   * @param bagCode  - string
   */
  public async addNewToBag(itemCode: string, bagCode: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/v2/bags/${bagCode}/items/${itemCode}/new`,
    };

    const response = await Axious.request<IOrderItemResponse>(requestOptions);
    return response.data;
  }

  /**
   * Call the add-existing-to-bag API
   * @param itemCode - string
   * @param bagCode - string
   */
  public async addExistingToBag(itemCode: string, bagCode: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/v2/bags/${bagCode}/items/${itemCode}`,
    };

    const response = await Axious.request<IOrderItemResponse>(requestOptions);
    return response.data;
  }

  /**
   * Link garment to RFID
   * @param rfid - string
   * @param itemCode - string
   * @param orderId - string
   */
  public async linkRfidToItem(rfid: string, itemCode: string, orderId: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/v2/orders/${orderId}/items/${itemCode}/rfid`,
      data: {
        rfid: rfid,
      },
    };
    const response = await Axious.request(requestOptions);
    return response.data;
  }

  /**
   * @description resets the bag from all items
   * @param bagCode
   */
  public async resetBag(bagCode: string, managerId: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'DELETE',
      url: `/bags/${bagCode}`,
      headers: {
        'manager-password': managerId,
      },
    };
    return await Axious.request(requestOptions);
  }

  public async updateBagType(bagCode: string, bagType: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'PUT',
      url: `/bags/${bagCode}/type`,
      data: {
        bagType,
      },
    };

    return await Axious.request(requestOptions);
  }

  public async createBag(orderId: string, bagCode: string, bagType: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/v2/orders/${orderId}/bags/${bagCode}`,
      data: { bagType },
    };
    const response = await Axious.request<IOrderBag>(requestOptions);

    return response.data.bag;
  }

  public async fetchCustomerTopBrands(customerId: string, type: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      baseURL: process.env.REACT_APP_TOP_BRANDS_API,
      params: {
        customerId: customerId,
        type: type,
      },
    };
    const response = await Axios.request<IBrandsResponse[]>(requestOptions);
    return response.data;
  }

  public async fetchAllBrands() {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      baseURL: process.env.REACT_APP_BRAND_API_HOST,
      url: 'brands.json',
    };
    // TODO use Axious instance here instead (override baseURL)
    const response = await Axios.request<IBrandsResponse[]>(requestOptions);
    return response.data;
  }

  public async rejectItem(rfid: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/items/${rfid}/reject`,
    };
    const response = await Axious.request(requestOptions);
    return response;
  }

  public async sendItemToApproval(rfid: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/items/${rfid}/approvals`,
    };
    const response = await Axious.request(requestOptions);
    return response;
  }
  /**
   * Send a POST request to the inform and proceed endpoint
   *
   * @param {string} rfid
   *
   * @returns {Object} response
   */
  public async informAndProceed(rfid: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/v2/items/${rfid}/inform-and-proceed`,
    };
    const response = await Axious.request(requestOptions);
    return response;
  }

  /**
   * Reject a recurring item
   * @param itemCode - string
   */
  public async rejectScannedItem(itemCode: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/v2/items/${itemCode}/reject-scanned`,
    };
    const response = await Axious.request(requestOptions);
    return response;
  }

  /**
   * @description report a list of stains (stain - damage) to backend
   * @param itemCode
   * @param reason
   * @param list
   */
  public async reportStained(itemCode: string, reason: 'STAINED' | 'DAMAGED' | 'NOT_CLEAN' | 'NOT_PRESSED', list: DetailsList[]) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/items/${itemCode}/report/${reason}`,
      data: {
        reportDetailsList: list,
      },
    };
    const response = await Axious.request(requestOptions);
    return response;
  }

  public async fetchPreselectedPrograms(type: string, color: string[]) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/items/programs/suggested`,
      params: {
        typeList: new Array(type),
        colorsList: color,
      },
    };
    const response = await Axious.request(requestOptions);
    return response.data.programs;
  }

  public async markBagAsSorted(bagCode: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/v2/bags/${bagCode}/sort`,
    };
    const response = await Axious.request(requestOptions);
    return response;
  }

  public async markAsTagged(bagCode: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/v2/bags/${bagCode}/tag`,
    };
    const response = await Axious.request(requestOptions);
    return response;
  }

  /**
   * @description creates a new item in the backend
   * @param rfid
   * @param item
   */
  public async createNewItem(rfid: string, item: IOrderItem | {} = {}) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/v2/items/${rfid}`,
      data: { ...item },
    };
    const response = await Axious.request<IOrderItemResponse>(requestOptions);
    return response.data.item;
  }

  /**
   * @description update item's details wether new or recurrent
   */
  public async updateItem(item: IOrderItem, managerId?: string, isWasher?: boolean) {
    const requestOptions: AxiosRequestConfig = {
      method: 'PUT',
      url: `/v2/items/${isWasher ? `${item.rfid}/realtime` : item.rfid}`,
      // headers: {
      //   'manager-password': managerId,
      // },
      data: { ...item },
    };
    const response = await Axious.request<IOrderItemResponse>(requestOptions);
    return response.data.item;
  }

  public async markItemAsSorted(rfid: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/v2/items/${rfid}/sort`,
    };
    const response = await Axious.request(requestOptions);
    return response;
  }

  public async uploadImageToS3(itemCode: string, imageBlob: string, isReport = false) {
    const d = new Date();
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/images`,
      data: {
        photoName: `${itemCode}-${d.getTime()}-front`,
        garmentCode: itemCode,
        base64Image: imageBlob,
        reported: isReport,
      },
    };
    const response = await Axious.request<{ photoUrl: string }>(requestOptions);
    return response.data.photoUrl;
  }

  public async removeItemFromBag(bagCode: string, itemCode: string, managerId = '') {
    const requestOptions: AxiosRequestConfig = {
      method: 'DELETE',
      url: `/v2/bags/${bagCode}/items/${itemCode}`,
      headers: {
        'manager-password': managerId,
      },
    };
    const response = await Axious.request(requestOptions);
    return response;
  }

  public async markItemAsPressed(rfid: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/items/${rfid}/press`,
    };

    const response = await Axious.request(requestOptions);
    return response;
  }

  /**
   * Fetch the list of orders for the racking station
   *
   * @return {Object} response
   */
  public async fetchOrdersList() {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/orders/hungbanned`,
    };

    const response = await Axious.request(requestOptions);
    return response.data.ordersList;
  }

  /**
   * Fetch the item by RFID with remaining Racks
   *
   * @param {string} RFID
   *
   * @return {Object} response
   */
  public async getItemByRfidWithRemainingRacks(rfid: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/items/rfid/${rfid}`,
    };
    const response = await Axious.request(requestOptions);
    return response.data;
  }

  /**
   * Link Special Attention Item to Bag
   *
   * @param {string} bagCode
   * @param {string} specialAttentionItemId
   *
   */
  public async linkSpecialAttentionItemToBag(bagCode: string, specialAttentionItemId: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/v2/bags/${bagCode}/special-attention-items/${specialAttentionItemId}`,
    };

    const response = await Axious.request(requestOptions);
    return response.data;
  }

  /**
   * Unlink Special Attention Item to Bag
   *
   * @param {string} bagCode
   * @param {string} specialAttentionItemId
   *
   */
  public async unlinkSpecialAttentionItemFromBag(bagCode: string, specialAttentionItemId: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'DELETE',
      url: `/v2/bags/${bagCode}/special-attention-items/${specialAttentionItemId}`,
    };

    const response = await Axious.request(requestOptions);
    return response.data;
  }

  /**
   * Link item to special attention Item
   *
   * @param {string} itemRfid
   * @param {string} specialAttentionItemId
   *
   */
  public async linkItemToSpecialAttentionItem(itemRfid: string, specialAttentionItemId: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/v2/items/${itemRfid}/special-attention-items/${specialAttentionItemId}`,
    };

    const response = await Axious.request(requestOptions);
    return response.data;
  }

  /**
   * Link item to special attention Item
   *
   * @param {string} itemRfid
   * @param {string} specialAttentionItemId
   *
   */
  public async unlinkItemToSpecialAttentionItem(itemRfid: string, specialAttentionItemId: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'DELETE',
      url: `/v2/items/${itemRfid}/special-attention-items/${specialAttentionItemId}`,
    };

    const response = await Axious.request(requestOptions);
    return response.data;
  }

  /**
   * Validat RFID on the Pressing stations
   *
   * @param {string} itemRfid
   */
  public async validatRfid(itemCode: string, itemRfid: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/items/${itemRfid}/validate`,
      params: {
        itemCode,
      },
    };

    const response = await Axious.request(requestOptions);
    return response.data;
  }

  /**
   * TODO -R: Refactor name to fetchItemInBagWithType()
   * List folding item in a given bag
   * @param {string} bagCode
   */
  public async fetchTransitItems(bagCode: string, category: FoldingType | 'ALL') {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/bags/${bagCode}/packaging-type/${category}/items`,
    };

    const response = await Axious.request(requestOptions);
    return response.data;
  }

  public async DEV_fetchTransitItems(bagCode: string, category: FoldingType) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/bags/${bagCode}/packaging-type/${category}/items`,
    };

    const response = await Axious.request(requestOptions);
    return response.data;
  }

  public async fetchFoldingItem(identifier: string, isManual: boolean = false) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/folding-station/items/itemIdentifier/${identifier}`,
      params: {
        isManual,
      },
    };

    const response = await Axious.request(requestOptions);
    return response.data;
  }

  public async fetchMultiFoldingItems(packageId: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/lots/${packageId}/items`,
    };

    const response = await Axious.request(requestOptions);
    return response.data;
  }

  public async markItemAsScanned(rfid: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/items/${rfid}/scanned-on-folding`,
    };

    const response = await Axious.request(requestOptions);
    return response.data;
  }

  public async completeFoldingPackage(orderId: string, bagCode: string, type: FoldingType, itemsCodesList: string[]) {
    const packageType = type === FoldingType.mp || type === FoldingType.banned ? 'MULTIPLE' : type;
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/orders/${orderId}/packages/${packageType}`,
      data: {
        itemsCodesList,
        bagCode,
      },
    };

    const response = await Axious.request(requestOptions);
    return response.data;
  }
  /**
   * Unpackages items from a package and
   * deletes the links associated with that
   * package.
   * @param packageId 
   */
  public async onFinishUnpackaging(packageId: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'DELETE',
      url: `/unpackage/${packageId}/items`,
    };

    const response = await Axious.request(requestOptions);
    return response.data;
  }
  /**
   *
   * @description Fetches all the items that belong to an Order
   * by either OrderAlphaId or ItemCode
   *
   * @param orderAlphaId
   * @param itemCode
   *
   * @returns orderSearchItemsList
   */
  public async fetchItemsByOrderAlphaId(orderAlphaId?: string, itemCode?: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/items/orderAlphaId`,
      params: {
        orderAlphaId,
        itemCode,
      },
    };

    const response = await Axious.request(requestOptions);
    return response.data;
  }
  public async getPackageDetail(rfid: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'GET',
      url: `/packages/items/${rfid}`,
    };

    const response = await Axious.request(requestOptions);
    return response.data;
  }
  public async rejectItemOnFolding(rfid: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/items/${rfid}/reject-folding`,
    };

    const response = await Axious.request(requestOptions);
    return response.data;
  }

  public async cancelItemOnFolding(rfid: string) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `/items/${rfid}/cancel-folding`,
    };

    const response = await Axious.request(requestOptions);
    return response.data;
  }
}

const stationService = new StationService();

// export as singleton
export default stationService;
