import React from 'react';
import { Typography, IconButton, Box, makeStyles, Theme, createStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';

import { WashmenIcon } from '../washmenIcon';

interface Props {
  message: string;
  type: 'error' | 'success' | 'good' | 'average' | 'bad';
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconSvg: {
      width: '3rem',
      height: '3rem',
    },
  })
);

const Toaster: React.FC<Props> = ({ message, type }) => {
  const classes = useStyles();
  const ICON_STATES = {
    error: <ErrorOutline color="error" />,
    success: <CheckCircleOutline color="primary" />,
    good: <WashmenIcon iconClassName={classes.iconSvg} name="goodJobIcon" svg isButton={false} />,
    average: <WashmenIcon iconClassName={classes.iconSvg} name="AverageJobIcon" svg isButton={false} />,
    bad: <WashmenIcon iconClassName={classes.iconSvg} name="SlowJobIcon" svg isButton={false} />,
  };
  return (
    <Box display="flex" flexDirection="row" alignItems="center" color="black" data-cy="toaster.component">
      <IconButton aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" color="inherit">
        {ICON_STATES[type]}
      </IconButton>
      <Typography variant="h6">
        <FormattedMessage id={message} defaultMessage={message} />
      </Typography>
    </Box>
  );
};

export default Toaster;
