import React from 'react';
import { observer } from 'mobx-react-lite';
import { Grid, Typography, makeStyles, createStyles } from '@material-ui/core';

import { useStationStore } from 'stores';

import CPIcon from 'assets/images/pngs/bagIcon/cp-bag.png';
import PIcon from 'assets/images/pngs/bagIcon/p-bag.png';
import HCIcon from 'assets/images/pngs/bagIcon/hc-bag.png';

const useStyles = makeStyles(() =>
  createStyles({
    bagIcon: {
      maxWidth: '100%',
    },
  })
);

interface TotalBagCount {
  totalBags: number;
  sortedBags: number;
  type: 'CP' | 'P' | 'HC';
}

const iconMapper = {
  CP: CPIcon,
  P: PIcon,
  HC: HCIcon,
};

export const BagCount: React.FC<TotalBagCount> = ({ totalBags, sortedBags, type }) => {
  const classes = useStyles();
  return (
    <Grid item container xs={4} alignItems="center" justify="space-between" spacing={2}>
      <Grid item xs={3}>
        <img src={iconMapper[type]} alt="bagIcons" className={classes.bagIcon} />
      </Grid>
      <Grid item xs={9}>
        <Typography variant="body1" className="light-typo">
          <b>{`${sortedBags}/${totalBags}`}</b>
          {` sorted`}
        </Typography>
      </Grid>
    </Grid>
  );
};

const BagCountDisplay: React.FC = observer(() => {
  const {
    bag: {
      order: { totalCPBags, totalPBags, totalHCBags, sortedCPBags, sortedPBags, sortedHCBags },
    },
  } = useStationStore();

  return (
    <Grid container spacing={4} justify="flex-end" alignItems="center">
      {totalCPBags > 0 && <BagCount totalBags={totalCPBags} sortedBags={sortedCPBags} type="CP" />}
      {totalPBags > 0 && <BagCount totalBags={totalPBags} sortedBags={sortedPBags} type="P" />}
      {totalHCBags > 0 && <BagCount totalBags={totalHCBags} sortedBags={sortedHCBags} type="HC" />}
    </Grid>
  );
});

export default BagCountDisplay;
