import { createContext, useContext } from 'react';
import { observable, action } from 'mobx';

class UiState {
  @observable isLoading = false;
  @observable showBackBtn = false;
  @observable closeBtnRoute = '';
  @observable showCustomerOrderId = false;
  @observable showPreferenceModal = true;

  @action.bound
  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  @action.bound
  setShowBackBtn(value: boolean) {
    this.showBackBtn = value;
  }

  @action.bound
  setCloseBtnRoute(value: string) {
    this.closeBtnRoute = value;
  }

  @action.bound
  setShowCustomerOrderId(value: boolean) {
    this.showCustomerOrderId = value;
  }

  @action.bound
  setShowPreferenceModal(value: boolean) {
    this.showPreferenceModal = value;
  }
}

export const uiState = new UiState();
const UiStateContext = createContext(uiState);

export const useUiState = () => useContext(UiStateContext);
