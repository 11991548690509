import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { useActivityState } from 'stores/activityStore';
import { useStationStore } from 'stores';
import ActivityCountComponent from './activityCount';

import WhiteBagIconS1 from 'assets/images/pngs/whiteIcons/whitebag-50.png';
import WhiteBagIconS2 from 'assets/images/pngs/whiteIcons/whitebag-56.png';
import WhiteBagIconS3 from 'assets/images/pngs/whiteIcons/whitebag-68.png';
import WhiteShirtIconS1 from 'assets/images/pngs/whiteIcons/whitetshirt-50.png';
import WhiteShirtIconS2 from 'assets/images/pngs/whiteIcons/whitetshirt-56.png';
import WhiteShirtIconS3 from 'assets/images/pngs/whiteIcons/whitetshirt-68.png';

const UserActivityCount: React.FC = observer(() => {
  const { activityStats } = useActivityState();
  const { activityType } = useStationStore();

  const isShirts = activityType === 'SHIRT';

  const Icons = [
    { src: isShirts ? WhiteShirtIconS1 : WhiteBagIconS1, count: activityStats.twoHourAgo, width: '40px', height: '40px' },
    { src: isShirts ? WhiteShirtIconS2 : WhiteBagIconS2, count: activityStats.oneHourAgo, width: '45px', height: '45px' },
    { src: isShirts ? WhiteShirtIconS3 : WhiteBagIconS3, count: activityStats.thisHour, width: '50px', height: '50px' },
  ];
  return (
    <>
      {activityType && (
        <>
          <ActivityCountComponent renderIcons={Icons}>
            <WrapperDiv>
              <TitleDiv>Sorted {activityType === 'BAG' ? 'Bags:' : activityType === 'SHIRT' ? 'Shirts' : ''}</TitleDiv>
              <ListStyle>
                <ListItem>{activityStats.thisHour} - This Hour</ListItem>
                <ListItem>{activityStats.oneHourAgo} - One Hour</ListItem>
                <ListItem>{activityStats.twoHourAgo} - Two Hour</ListItem>
              </ListStyle>
            </WrapperDiv>
          </ActivityCountComponent>
        </>
      )}
    </>
  );
});
export default UserActivityCount;

const WrapperDiv = styled.div`
  display: flex;
  min-width: 150px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TitleDiv = styled.div`
  font-size: 1.2rem;
  margin: 0.5rem 0;
`;

const ListItem = styled.li`
  padding: 0.25rem;
`;

const ListStyle = styled.ul`
  padding-inline-start: 0;
`;
