import { createContext, useContext } from 'react';
import { observable, action, computed } from 'mobx';

import { stationStore } from './stationStore';

import { IOrderItem } from 'utils/interfaces';
import { ScanType } from 'utils/enums/stationEnums';
import { StationService } from 'services';

type scanners = 'rfid' | 'qrCode' | 'barCode'

/**
 * @description packaging store to manage data
 */
export class PackagingStore {
  @observable itemsOfOrder: IOrderItem[] = [];
  @observable orderItems: IOrderItem[] = [];
  @observable indexedOrderItems: { [id: string]: IOrderItem } = {};
  @observable packageSerialId: string = '';
  @observable disabled: boolean = true;
  @observable renderedBannedItems: boolean = false;
  @observable initialPackageSerialId: string = '';
  @observable storedPrintResponse: string | undefined;
  @observable scanType: 'rfid' | 'qrCode' | 'barCode' | undefined = undefined;

  get fetchedOrderItems() {
    return this.itemsOfOrder.length > 0;
  }

  get orderId() {
    if (this.orderItems.length > 0) {
      return this.orderItems[0].orderId;
    }
    return '';
  }

  @computed get itemsCodeList() {
    return this.orderItems.map((item: IOrderItem) => {
      return item.code;
    });
  }

  /**
   * @description Store OrderItems
   */
  @action
  setOrderItems = (value: IOrderItem[]) => {
    this.orderItems = value;
  };

  @action
  setPackageId = (value: string) => {
    this.packageSerialId = value;
  };

  @action setStoredPrintResponse = (value: string | undefined) => {
    this.storedPrintResponse = value;
  };
  @action
  setInitialPackageSerialId = (value: string) => {
    this.initialPackageSerialId = value;
  };

  /**
   * @description Disable/Enable NEXT Button
   */
  @action
  setDisabled = (value: boolean) => {
    this.disabled = value;
  };

  /**
   * Set the scanner type on the banned packaging station
   * 
   * @param type - Scanner type
   */
  @action
  setScanType = (type: scanners) => {
    this.scanType = type
  }

  /**
   * Indicates if the banned items have been rendered
   *
   * @param value - true/false
   */
  @action
  setRenderedBannedItems = (value: boolean) => {
    this.renderedBannedItems = value;
  }

  /**
   * Set the array of all items in an order
   * 
   * @param allOrderItems - all items of an order
   */
  @action
  setItemsOfOrder = (allOrderItems: IOrderItem[]) => {
    this.itemsOfOrder = allOrderItems;
  }

  /**
   * Called when an item is scanned
   * 
   * @param itemCode - item code rfid/code
   */
  @action
  handleOnScanItem = async (code: string, codeType: ScanType) => {
    const getOrderItemFunctions: { [key in ScanType]?: () => Promise<IOrderItem> } = {
      rfid: async () => await StationService.getOrderItemByRfidCode(code),
      qrCode: async () => await StationService.getOrderItemByQrCode(code)
    }
    let item: IOrderItem = await getOrderItemFunctions[codeType]!();

    let itemsOfOrder: IOrderItem[];
    if (this.fetchedOrderItems) {

      itemsOfOrder = this.itemsOfOrder;

      if (this.orderId && this.orderId !== item.orderId) {
        throw new Error("Item doesn't belong to the same order");
      }
    } else {
      itemsOfOrder = await stationStore.fetchItemsByOrderAlphaId({ itemCode: code });

      this.setItemsOfOrder(itemsOfOrder);
    }

    if (this.renderedBannedItems) {
      this.indexedOrderItems[item.id] = { ...item, isScanned: true };
    } else {
      if (item.isBanned) {
        itemsOfOrder.forEach((i) => {
          if (i.isBanned) {
            this.indexedOrderItems[i.id] = { ...i, isScanned: item.id === i.id };
          }
        });
        this.setRenderedBannedItems(true);
      } else {
        this.indexedOrderItems[item.id] = { ...item, isScanned: true };
      }
    }

    this.setOrderItems(Object.values(this.indexedOrderItems));
    this.setDisabled(false);

  }

  /**
 * Called when a scanned item is deleted
 * 
 * @param orderItem - item
 */
  @action
  handleOnDeleteItem = (orderItem: IOrderItem) => {
    delete this.indexedOrderItems[orderItem.id];
    let items = Object.values(this.indexedOrderItems);
    this.setOrderItems(items);

    // if all the scanned items were deleted
    if (!items.length) {
      this.setDisabled(true);
    }
  }
}

const packagingStore = new PackagingStore();
const PackagingStoreContext = createContext(packagingStore);

export const usePackagingStore = () => useContext(PackagingStoreContext);
