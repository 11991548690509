import React from 'react';
import { useAuthStore } from 'stores/authStore';
import { Route, Redirect, RouteComponentProps } from 'react-router';
import { observer } from 'mobx-react-lite';

interface Props {
  component: any;
  [x: string]: any;
}

const PrivateRoute: React.FC<Props> = observer(({ component: Component, ...rest }) => {
  const { isLoggedIn } = useAuthStore();
  const routeGuard = (props: RouteComponentProps) => {
    const {
      match: { url },
    } = props;
    let renderEl = <Redirect to="/login" />;
    switch (url) {
      case '/login': {
        if (isLoggedIn) {
          renderEl = <Redirect to="/station" />;
        } else {
          renderEl = <Component {...props} />;
        }
        break;
      }
      case '/station': {
        if (isLoggedIn) {
          renderEl = <Component {...props} />;
        }
        break;
      }
    }
    return renderEl;
  };

  return <Route {...rest} render={routeGuard} />;
});

export default PrivateRoute;
