// Add Station Enums here

export enum StationTypes {
  Unselected = '',
  Tagger = 'tagger',
  Sorter = 'sorter',
  QA = 'QA',
  ShirtPressing = 'shirt-pressing',
  MultiPressing = 'multi-pressing',
  CollarAndCuffPressing = 'collar-cuff-pressing',
  PantsTopper = 'pants-topper',
  Spotting = 'Spotting',
  Washer = 'washer',
  HandWash = 'hand-wash',
  Scanner = 'scanner',
  Inspector = 'Inspector',
  BannedPackaging = 'banned-packaging',
  Folding = 'folding',
  FoldingLinen = 'folding-linen',
  Grouping = 'grouping',
  Racking = 'racking',
  QualityControl = 'quality-control',
  Spotter = 'spotter',
  Tailor = 'tailor',
}

export enum Locales {
  English = 'en',
  Arabic = 'ar',
  Chinese = 'zh-Hans',
}

export enum ScanType {
  RFID = 'rfid',
  QRCODE = 'qrCode',
  BARCODE = 'barcode',
  MULTI = 'multi'
}

export enum BagTypes {
  CLEAN_PRESS = 'CP',
  WASH_FOLD = 'WF',
  PRESS = 'P',
  HOME_CARE = 'HC',
}

export enum SorterSteps {
  type = 'type',
  brand = 'brand',
  programs = 'programs',
  color = 'color',
  photo = 'photo',
  stainDamage = 'stainDamage',
}

export enum SaDialogTypes {
  linkToItem = 'linkToItem',
  unlinkItem = 'unlinkItem',
  linkToBag = 'linkToBag',
  viewOnly = 'viewOnly',
  sorterViewOnly = 'sorterViewOnly',
  warning = 'warning',
}


export enum FoldingType {
  single = 'SINGLE',
  multiple = 'MULTIPLE',
  transit = 'TRANZIT',
  banned = 'BANNED',
  mp = 'MP'
}