import AbayaIcon from 'assets/images/pngs/GarmentTypesIcon/abaya.png';
import BathrobeIcon from 'assets/images/pngs/GarmentTypesIcon/bathrobe.png';
import BedsheetsIcon from 'assets/images/pngs/GarmentTypesIcon/bedsheets.png';
import BeltIcon from 'assets/images/pngs/GarmentTypesIcon/belt.png';
import BlouseIcon from 'assets/images/pngs/GarmentTypesIcon/blouse.png';
import BraIcon from 'assets/images/pngs/GarmentTypesIcon/bra.png';
import CarpetIcon from 'assets/images/pngs/GarmentTypesIcon/carpet.png';
import CoatIcon from 'assets/images/pngs/GarmentTypesIcon/coat.png';
import DuvetIcon from 'assets/images/pngs/GarmentTypesIcon/duvet.png';
import GathraIcon from 'assets/images/pngs/GarmentTypesIcon/gathra.png';
import JacketIcon from 'assets/images/pngs/GarmentTypesIcon/jacket.png';
import JeansIcon from 'assets/images/pngs/GarmentTypesIcon/jeans.png';
import JumpsuitIcon from 'assets/images/pngs/GarmentTypesIcon/jumpsuit.png';
import KanduraIcon from 'assets/images/pngs/GarmentTypesIcon/kandura.png';
import LeggingIcon from 'assets/images/pngs/GarmentTypesIcon/legging.png';
import LongDressIcon from 'assets/images/pngs/GarmentTypesIcon/longdress.png';
import OvercoatIcon from 'assets/images/pngs/GarmentTypesIcon/overcoat.png';
import PantsIcon from 'assets/images/pngs/GarmentTypesIcon/pants.png';
import SuitPantsIcon from 'assets/images/pngs/GarmentTypesIcon/suitpants.png';
import PullOverIcon from 'assets/images/pngs/GarmentTypesIcon/pullover.png';
import PillowCaseIcon from 'assets/images/pngs/GarmentTypesIcon/pillowcase.png';
import PillowIcon from 'assets/images/pngs/GarmentTypesIcon/pillow.png';
import ScarfIcon from 'assets/images/pngs/GarmentTypesIcon/skarf.png';
import ShirtIcon from 'assets/images/pngs/GarmentTypesIcon/shirt.png';
import ShortDressIcon from 'assets/images/pngs/GarmentTypesIcon/shortdress.png';
import ShortsIcon from 'assets/images/pngs/GarmentTypesIcon/short.png';
import SkirtIcon from 'assets/images/pngs/GarmentTypesIcon/skirt.png';
import SocksIcon from 'assets/images/pngs/GarmentTypesIcon/sock.png';
import SuitJacketIcon from 'assets/images/pngs/GarmentTypesIcon/suit-jacket.png';
import SweaterIcon from 'assets/images/pngs/GarmentTypesIcon/sweater.png';
import TshirtIcon from 'assets/images/pngs/GarmentTypesIcon/t-shirt.png';
import TableClothIcon from 'assets/images/pngs/GarmentTypesIcon/tablecloth.png';
import TableMatIcon from 'assets/images/pngs/GarmentTypesIcon/tableMat.png';
import TieIcon from 'assets/images/pngs/GarmentTypesIcon/tie.png';
import TowelIcon from 'assets/images/pngs/GarmentTypesIcon/towel.png';
import UndershirtIcon from 'assets/images/pngs/GarmentTypesIcon/undershirt.png';
import UnderwearIcon from 'assets/images/pngs/GarmentTypesIcon/underwear.png';
import VestIcon from 'assets/images/pngs/GarmentTypesIcon/vest.png';
import HandkerchiefIcon from 'assets/images/pngs/GarmentTypesIcon/handkerchief.png';
import ComforterCoverIcon from 'assets/images/pngs/GarmentTypesIcon/mattresscover.png';
import ComforterIcon from 'assets/images/pngs/GarmentTypesIcon/comforter.png';
import DoorMatIcon from 'assets/images/pngs/GarmentTypesIcon/doormat.png';
import BlanketSmallIcon from 'assets/images/pngs/GarmentTypesIcon/blanket.png';
import BlanketLargeIcon from 'assets/images/pngs/GarmentTypesIcon/blanket-large.png';
import BedCoverIcon from 'assets/images/pngs/GarmentTypesIcon/bedcover.png';
import SariIcon from 'assets/images/pngs/GarmentTypesIcon/saree.png';
import SherwaniIcon from 'assets/images/pngs/GarmentTypesIcon/sherwani.png';
import BathingSuitIcon from 'assets/images/pngs/GarmentTypesIcon/bathing-suit.png';
import PyjamaPantsIcon from 'assets/images/pngs/GarmentTypesIcon/pyjama-pant.png';
import CapIcon from 'assets/images/pngs/GarmentTypesIcon/cap.png';
import KurtaIcon from 'assets/images/pngs/GarmentTypesIcon/kurta.png';
import CurtainIcon from 'assets/images/pngs/GarmentTypesIcon/curtain.png';
import SofaCoverSmallIcon from 'assets/images/pngs/GarmentTypesIcon/sofacover.png';
import SofaCoverLargeIcon from 'assets/images/pngs/GarmentTypesIcon/sofacover-large.png';
import CushionSmallIcon from 'assets/images/pngs/GarmentTypesIcon/cushionsmall.png';
import CushionLargeIcon from 'assets/images/pngs/GarmentTypesIcon/cushionlarge.png';
import BackpackIcon from 'assets/images/pngs/GarmentTypesIcon/backpack.png';
import CarpetWoolIcon from 'assets/images/pngs/GarmentTypesIcon/woolcarpet.png';
import DuffelBagIcon from 'assets/images/pngs/GarmentTypesIcon/duffelbag.png';
import SheilaIcon from 'assets/images/pngs/GarmentTypesIcon/sheila.png';
import AccessoryIcon from 'assets/images/pngs/GarmentTypesIcon/accessory.png';
import LooseItemIcon from 'assets/images/pngs/GarmentTypesIcon/looseitem.png';
import JalabiyaIcon from 'assets/images/pngs/GarmentTypesIcon/jalabiya.png';
import DelicateAbayaIcon from 'assets/images/pngs/GarmentTypesIcon/delicate-abaya.png';
import FaceMaskIcon from 'assets/images/pngs/GarmentTypesIcon/face-mask.png';
import GloveIcon from 'assets/images/pngs/GarmentTypesIcon/gloves.png';

const itemTypeIconMap: { [key: string]: string } = {
  ABAYA: AbayaIcon,
  BATHROBE: BathrobeIcon,
  BEDSHEET: BedsheetsIcon,
  BELT: BeltIcon,
  BLOUSE: BlouseIcon,
  BRA: BraIcon,
  CARPET: CarpetIcon,
  COAT: CoatIcon,
  DUVET: DuvetIcon,
  GATHRA: GathraIcon,
  JACKET: JacketIcon,
  JEANS: JeansIcon,
  JUMPSUIT: JumpsuitIcon,
  KANDURA: KanduraIcon,
  LEGGING: LeggingIcon,
  DRESS_LONG: LongDressIcon,
  OVERCOAT: OvercoatIcon,
  PANTS: PantsIcon,
  PILLOW_CASE: PillowCaseIcon,
  PILLOW: PillowIcon,
  PULLOVER: PullOverIcon,
  SCARF: ScarfIcon,
  SHIRT: ShirtIcon,
  DRESS_SHORT: ShortDressIcon,
  SHEILA: SheilaIcon,
  SHORTS: ShortsIcon,
  SKIRT: SkirtIcon,
  SOCKS: SocksIcon,
  SUIT_JACKET: SuitJacketIcon,
  SWEATER: SweaterIcon,
  T_SHIRT: TshirtIcon,
  TABLE_CLOTH: TableClothIcon,
  TIE: TieIcon,
  TOWEL: TowelIcon,
  UNDERSHIRT: UndershirtIcon,
  UNDERWEAR: UnderwearIcon,
  VEST: VestIcon,
  HANDKERCHIEF: HandkerchiefIcon,
  COMFORTER: ComforterIcon,
  COMFORTER_COVER: ComforterCoverIcon,
  TABLE_MAT: TableMatIcon,
  DOORMAT: DoorMatIcon,
  BLANKET: BlanketSmallIcon,
  BLANKET_LARGE: BlanketLargeIcon,
  BEDCOVER: BedCoverIcon,
  SUIT_PANTS: SuitPantsIcon,
  SARI: SariIcon,
  SHERWANI: SherwaniIcon,
  BATHING_SUIT: BathingSuitIcon,
  PYJAMA_PANTS: PyjamaPantsIcon,
  CAP: CapIcon,
  KURTA_MENS: KurtaIcon,
  CURTAIN_SQM: CurtainIcon,
  SOFA_COVER_REGULAR: SofaCoverSmallIcon,
  SOFA_COVER_LARGE: SofaCoverLargeIcon,
  CUSHION_COVER_SMALL: CushionSmallIcon,
  CUSHION_COVER_LARGE: CushionLargeIcon,
  CARPET_REGULAR_PERSQM: CarpetIcon,
  CARPET_WOOL: CarpetWoolIcon,
  DUFFEL_BAG: DuffelBagIcon,
  BACKPACK: BackpackIcon,
  LOOSE_ITEM: LooseItemIcon,
  ACCESSORY: AccessoryIcon,
  JALABIYA: JalabiyaIcon,
  DELICATE_ABAYA: DelicateAbayaIcon,
  FACE_MASK: FaceMaskIcon,
  GLOVE: GloveIcon,
};

export default itemTypeIconMap;
