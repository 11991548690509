import React, { useEffect, useReducer } from 'react';
import styled from 'styled-components';

import { Preferences, DetailsList } from 'utils/interfaces/stationInterfaces';
import PreferenceBox from './preferenceBox';
import { FoldingType } from 'utils/enums/stationEnums';

import { IOrderItem } from 'utils/interfaces';
interface Props {
  customerPref: Preferences;
  reportsList?: DetailsList[];
  item?: IOrderItem;
  hasCSR?: boolean;
  hasFoldingType?: FoldingType;
  specific?: 'pantsCrease' | 'shirtCrease' | 'gathraCrease' | 'kanduraCrease' | 'shortsCrease' | 'starch' | 'basicDetails';
  displayRow?: boolean;
  size?: 'sm' | 'md' | 'lg';
}

interface Action {
  type: string;
  payload: string | boolean | IPreference;
}

const addPantsCrease = (pantsCrease: boolean) => {
  return {
    type: 'ADD_PANTS_CREASE',
    payload: pantsCrease,
  };
};

const addShirtCrease = (shirtCrease: boolean) => {
  return {
    type: 'ADD_SHIRT_CREASE',
    payload: shirtCrease,
  };
};

const addGathraCrease = (gathraCrease: string) => {
  return {
    type: 'ADD_GATHRA_CREASE',
    payload: gathraCrease,
  };
};

const addKanduraCrease = (kanduraCrease: string) => {
  return {
    type: 'ADD_KANDURA_CREASE',
    payload: kanduraCrease,
  };
};
const addShortsCrease = (shortsCrease: boolean) => {
  return {
    type: 'ADD_SHORTS_CREASE',
    payload: shortsCrease,
  };
};

const addStarch = (starch: string) => {
  return {
    type: 'ADD_STARCH',
    payload: starch,
  };
};

const addPreference = (reportObj: IPreference) => {
  return {
    type: 'ADD_PREFERENCE',
    payload: reportObj,
  };
};

const preferenceReducer = (state = {}, action: Action) => {
  switch (action.type) {
    case 'ADD_STAIN':
      return { hasStain: action.payload, ...state };
    case 'ADD_DAMAGE':
      return { hasDamage: action.payload, ...state };
    case 'ADD_FOLDING':
      return { folding: action.payload, ...state };
    case 'ADD_PANTS_CREASE':
      return { pantsCrease: action.payload, ...state };
    case 'ADD_SHIRT_CREASE':
      return { shirtCrease: action.payload, ...state };
    case 'ADD_GATHRA_CREASE':
      return { gathraCrease: action.payload, ...state };
    case 'ADD_KANDURA_CREASE':
      return { kanduraCrease: action.payload, ...state };
    case 'ADD_SHORTS_CREASE':
      return { shortsCrease: action.payload, ...state };
    case 'ADD_STARCH':
      return { starch: action.payload, ...state };
    case 'ADD_NO_CREASE':
      return { ...state, withoutCrease: action.payload };
    case 'ADD_PREFERENCE':
      return { ...(action.payload as IPreference) };
    default:
      return state;
  }
};

const hasThisCrease = (creases: string, creaseType: string) => {
  if (creases.length <= 0) return false;
  const hasCrease = creases
    .toLowerCase()
    .split(',')
    .find((crease) => (crease.toLowerCase().includes(creaseType) ? true : false));
  return hasCrease && hasCrease.length > 0 ? true : false;
};

const hasCreaseValue = (creases: string, creaseType: string) => {
  if (!creases || creases.length <= 0) return '';
  return creases
    .toLowerCase()
    .split(',')
    .find((crease) => (crease.toLowerCase().includes(creaseType) ? true : false));
};

interface IPreference {
  hasStain: boolean;
  hasDamage: boolean;
  folding: boolean;
  pantsCrease?: boolean;
  shirtCrease?: boolean;
  gathraCrease?: string;
  kanduraCrease?: string;
  shortsCrease?: boolean;
  withoutCrease?: boolean;
  starch?: string;
  foldingType?: FoldingType | undefined;
  hasCSR: boolean;
  hasCardboard: boolean;
}
/** CustomerPreferences  */
const CustomerPreferences: React.FC<Props> = ({
  customerPref,
  reportsList = [],
  item,
  hasFoldingType,
  hasCSR = false,
  specific = '',
  displayRow = false,
  size = 'md',
}) => {
  const { folding = '', creases = '', starch = '' } = customerPref;

  const [state, dispatch] = useReducer(preferenceReducer, {});

  const hasStain = reportsList.findIndex((item) => item.reason === 'STAINED') !== -1 ? true : false;
  const hasDamage = reportsList.findIndex((item) => item.reason === 'DAMAGED') !== -1 ? true : false;
  const hasCardboard = customerPref.packaging && customerPref.packaging.hasCardboard ? true : false;
  const hasFolding = (item && item.packagingDetails && item.packagingDetails.packageType === 'FOLDED') || false;
  const creasesPants = hasThisCrease(creases, 'pants') || false;
  const creasesShirts = hasThisCrease(creases, 'shirt') || false;
  const creasesGathra = hasCreaseValue(creases, 'gathra') || '';
  const creasesKandura = hasCreaseValue(creases, 'kandura') || '';
  const creasesShorts = hasThisCrease(creases, 'shorts') || false;

  useEffect(() => {
    switch (specific) {
      case 'starch':
        dispatch(addStarch(starch));
        break;
      case 'shirtCrease':
        if (creasesShirts) dispatch(addShirtCrease(creasesShirts));
        break;
      case 'pantsCrease':
        if (creasesPants) dispatch(addPantsCrease(creasesPants));
        break;
      case 'shortsCrease':
        if (creasesShorts) dispatch(addShortsCrease(creasesShorts));
        break;
      case 'gathraCrease':
        if (creasesGathra && creasesGathra !== '') dispatch(addGathraCrease(creasesGathra));
        break;
      case 'kanduraCrease':
        if (creasesKandura && creasesKandura !== '') dispatch(addKanduraCrease(creasesKandura));
        break;
      case 'basicDetails':
        dispatch(
          addPreference({
            hasStain: hasStain ? true : false,
            hasDamage: hasDamage ? true : false,
            folding: hasFolding,
            hasCSR,
            foldingType: hasFoldingType === FoldingType.single || hasFoldingType === FoldingType.multiple ? hasFoldingType : undefined,
            hasCardboard,
            withoutCrease: !creasesShirts && !creasesPants && !creasesGathra && !creasesKandura && !creasesShorts,
          })
        );
        break;
      case '':
        const reportInfo: IPreference = {
          hasStain: hasStain ? true : false,
          hasDamage: hasDamage ? true : false,
          folding: Boolean(folding && folding !== ''),
          starch: starch && starch !== '' ? starch : 'NONE',
          hasCSR,
          foldingType: hasFoldingType ? hasFoldingType : undefined,
          hasCardboard,
          pantsCrease: creasesPants,
          shirtCrease: creasesShirts,
          gathraCrease: creasesGathra,
          kanduraCrease: creasesKandura,
          shortsCrease: creasesShorts,
          withoutCrease: !creasesShirts && !creasesPants && !creasesGathra && !creasesKandura,
        };
        dispatch(addPreference(reportInfo));
        break;
    }
  }, [
    creasesGathra,
    creasesKandura,
    creasesPants,
    creasesShirts,
    creasesShorts,
    folding,
    hasDamage,
    hasStain,
    specific,
    starch,
    hasCardboard,
    hasFoldingType,
    hasCSR,
    hasFolding,
  ]);

  return (
    <Wrapper size={size} displayRow={displayRow}>
      {Object.keys(state).map((instruction, index) => {
        return state[instruction as keyof typeof state] || instruction === 'folding' ? (
          <PreferenceBox instruction={instruction} value={state[instruction as keyof typeof state]} key={`${index}-customer-pref`} size={size} />
        ) : (
          ''
        );
      })}
    </Wrapper>
  );
};

export default CustomerPreferences;

interface WrapperProps {
  size: 'sm' | 'md' | 'lg';
  displayRow: boolean;
}
const Wrapper = styled.div<WrapperProps>`
  width: 100%;

  display: flex;
  flex-direction: ${(props) => (props.displayRow ? 'row' : 'column')};

  ${(props) => (props.displayRow ? 'flex-wrap: wrap;' : '')}
`;
