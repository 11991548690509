import React, { useState, ChangeEvent, useRef, useEffect, useCallback, useImperativeHandle } from 'react';
import { Box, TextField, makeStyles, Typography, createStyles, Theme } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { fromEvent, Subscription } from 'rxjs';
import { tap, skipWhile, debounceTime } from 'rxjs/operators';
import { FromEventTarget } from 'rxjs/internal/observable/fromEvent';
/** Import Toaster  */
import { toast } from 'react-toastify';
import { Toaster } from '../toaster';

import { ScanType } from 'utils/enums/stationEnums';
import { useUiState, useStationStore } from 'stores';

import './styles.scss';

import { ReactComponent as ColoredRfidImage } from 'assets/images/svgs/scanrfid.svg';

export interface ImageIcon {
  imageUrl?: string;
  type: ScanType;
  width?: string;
}

export type QrCodeType = 'item' | 'bag';

// TODO: add enum for all possible types
interface Props {
  imageIcon: ImageIcon;
  title?: string;
  onFormSubmit?: (value: string, type: ScanType) => void;
  validateInput?: boolean;
  qrCodeType?: QrCodeType;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => any;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => any;
  isVisible?: boolean;
  autoFocus?: boolean;
  coloredSvgFlag?: boolean;
  svgClass?: string;
}

const defaultProps = {
  title: 'main.scanQrCode',
  onChangeInput: () => console.log('default input value'),
  onFormSubmit: () => alert('form submitted'),
};

// regular expression validations
const regexQrCodeTypeMapper: { [key in QrCodeType]: RegExp } = {
  item: /^[0-9]{12}$/,
  bag: /.*/,
};

const rfidRegex = /^[a-z0-9]{24}$/;

const isProd = process.env.NODE_ENV === 'production';
// const isProd = true;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      width: '50%',
      marginTop: theme.spacing(5),
    },
    title: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(8),
      textTransform: 'capitalize',
      textAlign: 'center',
    },
  })
);

export interface ScannerRefProps {
  focusInput: () => any;
}

const Scanner = React.forwardRef<ScannerRefProps, Props>(
  (
    {
      onFormSubmit = defaultProps.onFormSubmit,
      title = '',
      imageIcon,
      validateInput = false,
      qrCodeType,
      onBlur = () => {},
      onFocus = () => {},
      isVisible = true,
      autoFocus = true,
      svgClass,
      coloredSvgFlag,
    },
    ref
  ) => {
    let inputEl = useRef<HTMLInputElement>(null);
    const formEl = useRef(null);
    const [code, setCode] = useState('');
    const classes = useStyles();
    const [blurEvent$, setBlurEvent$] = useState<Subscription>();
    const [currentFocusState, setCurrentFocusState] = useState(true);
    const { isLoading } = useUiState();
    const { focusInput$ } = useStationStore();
    const showForm = isProd ? 'invisible' : '';

    useImperativeHandle(ref, () => ({
      focusInput: () => {
        if (inputEl.current) inputEl.current.focus();
      },
    }));

    useEffect(() => {
      const disposer = focusInput$.intercept((change) => {
        setCurrentFocusState(change.newValue);
        if (!change.newValue) {
          // unsubscribe
          if (blurEvent$) blurEvent$.unsubscribe();
          return change;
        }
        // trigger resubscribe
        setTimeout(() => {
          if (inputEl.current) inputEl.current.focus();
        }, 100);
        return change;
      });
      return () => {
        disposer();
      };
    });

    const memoizedCallback = useCallback(() => {
      const { type } = imageIcon;
      if (validateInput && isProd) {
        if (type === 'qrCode' && qrCodeType) {
          if (regexQrCodeTypeMapper[qrCodeType].test(code)) {
            onFormSubmit(code, type);
          } else {
            toast(<Toaster message="Invalid QR Code" type="error" />);
          }
        } else if (type === 'rfid') {
          // RFID Validation
          if (rfidRegex.test(code)) {
            onFormSubmit(code, type);
          } else {
            toast(<Toaster message="Invalid RFID Code" type="error" />);
          }
        } else if (type === 'multi') {
          // Check if it's a QR code
          if (regexQrCodeTypeMapper.item.test(code)) {
            onFormSubmit(code, ScanType.QRCODE);

            // Check if it's an RFID
          } else if (rfidRegex.test(code)) {
            onFormSubmit(code, ScanType.RFID);
          } else {
            toast(<Toaster message="Invalid QR code / RFID tag" type="error" />);
          }
        } else {
          onFormSubmit(code, ScanType.BARCODE);
        }
      } else {
        // No Validation on DEV
        onFormSubmit(code, type);
      }
      setCode(''); // clear code value in case an error happened
    }, [code, onFormSubmit, imageIcon, validateInput, qrCodeType]);

    const onInputValueChange = (event: ChangeEvent<HTMLInputElement>) => {
      setCode(event.target.value);
    };

    useEffect(() => {
      // exhaust submitting
      // TODO: use exhaust map instead
      const inputSubmitEvent = fromEvent(formEl.current! as FromEventTarget<ChangeEvent<HTMLFormElement>>, 'submit').pipe(
        tap((event) => event.preventDefault()),
        skipWhile(() => isLoading === true),
        tap(() => {
          memoizedCallback();
        })
      );
      const inputSubmitEvent$ = inputSubmitEvent.subscribe();

      return () => {
        inputSubmitEvent$.unsubscribe();
      };
    }, [formEl, isLoading, memoizedCallback]);

    useEffect(() => {
      // we use this effect to listen to onBlur event stream from the input.
      // we debounce the event so it wont get trigerred often
      if (!isVisible && currentFocusState) {
        const inputBlurEvent = fromEvent(inputEl.current! as FromEventTarget<ChangeEvent<HTMLInputElement>>, 'blur').pipe(
          debounceTime(500),
          tap((event) => {
            event.target.focus();
          })
        );
        const inputBlurEvent$ = inputBlurEvent.subscribe();
        setBlurEvent$(inputBlurEvent$);
        return () => {
          // clean up logic
          inputBlurEvent$.unsubscribe();
        };
      }
    }, [inputEl, isVisible, currentFocusState]);

    const { type, imageUrl, width } = imageIcon;
    return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" height={isVisible ? '100%' : '0'}>
        {!!title && (
          <Typography variant="h1" className={classes.title}>
            <FormattedMessage id={title} defaultMessage={title} />
          </Typography>
        )}
        <Box display="flex" alignItems="center" justifyContent="space-around" flex="1" alignSelf="stretch" padding="0rem 15%">
          <Box border="0.3rem solid transparent" padding="1rem" display="flex">
            {coloredSvgFlag ? (
              <ColoredRfidImage className={svgClass} />
            ) : imageUrl && type ? (
              <Box border="0.3rem solid transparent" padding="1rem" display="flex">
                <img style={{ maxWidth: '100%', width: width ? width : 'auto' }} src={imageUrl} alt="" />
              </Box>
            ) : (
              ''
            )}
          </Box>
        </Box>

        <form className={`${classes.form} ${showForm}`} autoComplete="off" ref={formEl}>
          <TextField
            name="rfidValue"
            type="text"
            placeholder="input your RFID manually here..."
            value={code}
            onChange={onInputValueChange}
            autoFocus={autoFocus}
            fullWidth
            required
            inputRef={inputEl}
            onFocus={(event: React.FocusEvent<HTMLInputElement>) => onFocus(event)}
            onBlur={(event: React.FocusEvent<HTMLInputElement>) => onBlur(event)}
            onInput={() => (inputEl.current!.value = inputEl.current!.value.toLocaleLowerCase())}
            data-cy={qrCodeType ? `${qrCodeType}.scanner` : `rfid.scanner`}
          />
        </form>
      </Box>
    );
  }
);

export default Scanner;
