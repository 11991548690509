import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Menu } from '@material-ui/core';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

interface ActivityCountProps {
  renderIcons: { count: number; src: string; width: string; height: string }[];
}

const ActivityCount: React.FC<ActivityCountProps> = observer(({ renderIcons, children }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu} color="inherit">
        <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
          {renderIcons.map(({ src, count, width, height }, i) => (
            <ActivityCountIcon {...{ src, count, width, height }} key={i} />
          ))}
        </div>
      </IconButton>
      <StyledMenu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        getContentAnchorEl={null}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        onClose={handleClose}
      >
        {children}
      </StyledMenu>
    </>
  );
});

export default ActivityCount;

export const ActivityCountIcon: React.FC<{ count: number; src: string; width: string; height: string; type?: 'SHIRT' | 'BAG' }> = ({
  count,
  type,
  src,
  width,
  height,
}) => {
  return (
    <div style={{ position: 'relative', display: 'flex', alignItems: 'flex-end', justifyContent: 'center', height: '50px' }}>
      <img src={src} alt="" width={width} height={height} />
      <div style={{ position: 'absolute', color: 'blue' }}>{count}</div>
    </div>
  );
};

const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    border: #b6bcd1 3px solid;
    border-radius: 6px;
  }
`;
