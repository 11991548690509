import React, { useState, useEffect } from 'react';
import { ButtonBase, makeStyles, createStyles, Theme } from '@material-ui/core';

import StainIcon from 'assets/images/pngs/stain-inactive.png';
import StainIconActive from 'assets/images/pngs/stain-active.png';
import PantStainIcon from 'assets/images/pngs/pantstain-inactive.png';
import PantStainIconActive from 'assets/images/pngs/pantstain-active.png';
import DamageIconActive from 'assets/images/pngs/damage-active.png';
import DamageIcon from 'assets/images/pngs/damage-inactive.png';
import NetIconActive from 'assets/images/pngs/net-active.png';
import NetIcon from 'assets/images/pngs/net-inactive.png';
import GreenBagCircleIcon from 'assets/images/pngs/bags/green-bag-inactive@3x.png';
import GreenBagCircleIconActive from 'assets/images/pngs/bags/green-bag-active.png';
import BlueBagCircleIcon from 'assets/images/pngs/bags/blue-bag-inactive.png';
import BlueBagCircleIconActive from 'assets/images/pngs/bags/blue-bag-active.png';
import PinkBagCircleIcon from 'assets/images/pngs/bags/pink-bag.png';
import PinkBagCircleIconActive from 'assets/images/pngs/bags/pink-bag-active.png';

import WhiteBagCircleIcon from 'assets/images/pngs/bags/white-bag-inactive@3x.png';
import WhiteBagCircleIconActive from 'assets/images/pngs/bags/white-bag-active.png';
import QrIconActive from 'assets/images/pngs/qrcode-active.png';
import RfidIcon from 'assets/images/pngs/rfid-inactive.png';
import RfidIconActive from 'assets/images/pngs/rfid-active.png';
import WasherIcon from 'assets/images/pngs/washer-inactive.png';
import WasherActiveIcon from 'assets/images/pngs/washer-active.png';

import { ReactComponent as DamagedIcon } from 'assets/images/svgs/borderless-damage-inactive.svg';
import { ReactComponent as StainedIcon } from 'assets/images/svgs/borderless-stain-inactive.svg';
import { ReactComponent as CameraIcon } from 'assets/images/svgs/photoFront.svg';
import { ReactComponent as VipIcon } from 'assets/images/svgs/vip.svg';
import { ReactComponent as IronIcon } from 'assets/images/svgs/iron.svg';
import { ReactComponent as SprayIcon } from 'assets/images/svgs/spray.svg';
import { ReactComponent as CreaseIcon } from 'assets/images/svgs/crease.svg';
import { ReactComponent as PlusIcon } from 'assets/images/svgs/plus.svg';
import { ReactComponent as TimerIcon } from 'assets/images/svgs/timer.svg';
import { ReactComponent as GoodJobIcon } from 'assets/images/svgs/goodjob.svg';
import { ReactComponent as AverageJobIcon } from 'assets/images/svgs/average-face.svg';
import { ReactComponent as SlowJobIcon } from 'assets/images/svgs/slow-face.svg';
import { ReactComponent as BarCodeIcon } from 'assets/images/svgs/noun_barcode_1720931.svg';
import { ReactComponent as QrCodeIcon } from 'assets/images/svgs/barcode-icon.svg';
import { ReactComponent as RfidCodeIcon } from 'assets/images/svgs/rfid-gray.svg';
import { ReactComponent as BucketIcon } from 'assets/images/svgs/bucket.svg';

export type PNGIconTypes =
  | 'stain'
  | 'pantstain'
  | 'damage'
  | 'rewash'
  | 'net'
  | 'greenBagCircle'
  | 'blueBagCircle'
  | 'pinkBagCircle'
  | 'whiteBagCircle'
  | 'qrCode'
  | 'rfid';

export type SVGIconTypes =
  | 'damageIcon'
  | 'stainIcon'
  | 'vipIcon'
  | 'cameraIcon'
  | 'ironIcon'
  | 'creaseIcon'
  | 'sprayIcon'
  | 'plusIcon'
  | 'timerIcon'
  | 'goodJobIcon'
  | 'AverageJobIcon'
  | 'SlowJobIcon'
  | 'barCodeIcon'
  | 'qrCodeIcon'
  | 'rfidCodeIcon'
  | 'bucket';

interface Props {
  name: PNGIconTypes | SVGIconTypes;
  active?: boolean;
  width?: string;
  height?: string;
  iconClassName?: string;
  isSquare?: boolean;
  svg?: boolean;
  isButton?: boolean;
  onToggleClick?: (active: boolean) => void;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    circle: {
      borderRadius: '50%',
    },
  })
);

const iconSVGNameMap: { [key in SVGIconTypes]: (className: string, height?: string, width?: string) => JSX.Element } = {
  damageIcon: (className) => {
    return <DamagedIcon className={className} />;
  },
  stainIcon: (className) => {
    return <StainedIcon className={className} />;
  },
  cameraIcon: (className) => {
    return <CameraIcon className={className} />;
  },
  vipIcon: (className) => {
    return <VipIcon className={className} />;
  },
  ironIcon: (className) => {
    return <IronIcon className={className} />;
  },
  sprayIcon: (className) => {
    return <SprayIcon className={className} />;
  },
  creaseIcon: (className) => {
    return <CreaseIcon className={className} />;
  },
  plusIcon: (className) => {
    return <PlusIcon className={className} />;
  },
  timerIcon: (className) => {
    return <TimerIcon className={className} />;
  },
  AverageJobIcon: (className) => {
    return <AverageJobIcon className={className} />;
  },
  SlowJobIcon: (className) => {
    return <SlowJobIcon className={className} />;
  },
  goodJobIcon: (className) => {
    return <GoodJobIcon className={className} />;
  },
  barCodeIcon: (className, height, width) => {
    return <BarCodeIcon height={height} width={width} className={className} />;
  },
  qrCodeIcon: (className, height, width) => {
    return <QrCodeIcon height={height} width={width} className={className} />;
  },
  rfidCodeIcon: (className, height, width) => {
    return <RfidCodeIcon height={height} width={width} className={className} />;
  },
  bucket: (className, height, width) => {
    return <BucketIcon height={height} width={width} className={className} />;
  },
};

const iconNameMap: { [key: string]: { active: string; inActive: string } } = {
  stain: {
    active: StainIconActive,
    inActive: StainIcon,
  },
  pantstain: {
    active: PantStainIconActive,
    inActive: PantStainIcon,
  },
  damage: {
    active: DamageIconActive,
    inActive: DamageIcon,
  },
  rewash: {
    active: WasherActiveIcon,
    inActive: WasherIcon,
  },
  net: {
    active: NetIconActive,
    inActive: NetIcon,
  },
  greenBagCircle: {
    active: GreenBagCircleIconActive,
    inActive: GreenBagCircleIcon,
  },
  whiteBagCircle: {
    active: WhiteBagCircleIconActive,
    inActive: WhiteBagCircleIcon,
  },
  blueBagCircle: {
    active: BlueBagCircleIconActive,
    inActive: BlueBagCircleIcon,
  },
  pinkBagCircle: {
    active: PinkBagCircleIconActive,
    inActive: PinkBagCircleIcon,
  },
  qrCode: {
    active: QrIconActive,
    inActive: QrIconActive,
  },
  rfid: {
    active: RfidIconActive,
    inActive: RfidIcon,
  },
};

const defaultProps = {
  onToggleClick: (active: boolean) => console.log('no click handler'),
  iconClassName: '',
  width: '5rem',
};

const WashmenIcon: React.FC<Props> = ({
  active = false,
  svg = false,
  isButton = true,
  isSquare = false,
  name,
  onToggleClick = defaultProps.onToggleClick,
  width = defaultProps.width,
  height,
  iconClassName = defaultProps.iconClassName,
}) => {
  const [iconSrc, setIconSrc] = useState('');
  useEffect(() => {
    if (svg) {
      return;
    }
    active ? setIconSrc(iconNameMap[name].active) : setIconSrc(iconNameMap[name].inActive);
  }, [active, name, svg]);
  const toggleHandler = () => {
    onToggleClick(active);
  };
  const classes = useStyles();
  return (
    <ButtonBase
      onClick={toggleHandler}
      focusRipple
      className={isSquare ? '' : classes.circle}
      component={isButton ? 'button' : 'div'}
      disableRipple={!isButton}
    >
      {!svg ? (
        <img src={iconSrc} alt="washmen icon" className={iconClassName} style={{ width: width }} />
      ) : (
        iconSVGNameMap[name as SVGIconTypes](iconClassName, height, width)
      )}
    </ButtonBase>
  );
};

export default WashmenIcon;
