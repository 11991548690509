import { IOrderItem } from "utils/interfaces";

export interface ITypeMapper {
    [key: string]: IOrderItem[];
}

/**
 * @description Creates an object with the type as key and itemsList as value
 *
 * @param {IOrderItem} itemsList
 * 
 * @example typeMapperHelper(items) 
 */
export const typeMapperHelper = (itemsList: IOrderItem[]): ITypeMapper => {
    // TODO: Fix
    // eslint-disable-next-line array-callback-return
    return itemsList.reduce((acc: any, currentValue: IOrderItem) => {
        const { type } = currentValue;
        if (type) {
            if (acc[type]) {
                // Sort the items according to the isPackaged flag (Packaged item first)
                return { ...acc, [type]: [...acc[type], currentValue].sort((itemA: IOrderItem) => (Boolean(itemA.isPackaged) ? -1 : 1)) };
            }

            return { ...acc, [type]: [currentValue] };
        }
    }, {});
};