import React, { useRef, useState } from 'react';
import Scanner, { ImageIcon, QrCodeType, ScannerRefProps } from './scanner';
import WidgetBox from '../widgetBox/widgetBox';
import { ScanType } from 'utils/enums/stationEnums';
import { makeStyles, createStyles, Theme, Box } from '@material-ui/core';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
interface Props {
  onScanComplete: (value: string, type: ScanType) => void;
  images?: ImageIcon;
  border?: boolean;
  padding?: string;
  title?: string;
  qrCodeType?: QrCodeType;
  validateInput?: boolean;
  isVisible?: boolean;
  autoFocus?: boolean;
  button?: JSX.Element;
  svgClass?: string;
  coloredSvgFlag?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hidden: {
      width: 0,
      height: 0,
      padding: 0,
      margin: 0,
      border: '0 !important',
      flex: 0,
    },
    button: {
      padding: '1rem',
    },
  })
);

const ScannerWidget: React.FC<Props> = ({
  onScanComplete,
  children,
  border = true,
  padding = '',
  images = {} as ImageIcon,
  title = '',
  qrCodeType,
  validateInput,
  isVisible = true,
  autoFocus = true,
  button,
  svgClass,
  coloredSvgFlag,
}) => {
  const [active, setActive] = useState(false);
  const classes = useStyles();
  const scannerRef = useRef<ScannerRefProps>(null);

  const handleScannerClick = () => {
    if (scannerRef.current) scannerRef.current.focusInput();
  };

  return (
    <WidgetBox padding={padding} border={border} classNames={isVisible ? '' : classes.hidden} onClick={handleScannerClick}>
      {active ? <CheckCircleOutline color="primary" /> : <ErrorOutline color="secondary" />}
      <Scanner
        title={title}
        imageIcon={images}
        onFormSubmit={onScanComplete}
        qrCodeType={qrCodeType}
        validateInput={validateInput}
        onBlur={() => setActive(false)}
        onFocus={() => setActive(true)}
        ref={scannerRef}
        isVisible={isVisible}
        autoFocus={autoFocus}
        svgClass={svgClass}
        coloredSvgFlag={coloredSvgFlag}
      />
      <Box display="flex" className={classes.button} justifyContent="center">
        {button}
        {children}
      </Box>
    </WidgetBox>
  );
};

export default ScannerWidget;
