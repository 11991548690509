import { observable, action, computed, runInAction, autorun } from 'mobx';
import { AuthService } from '../services';
import { stationStore } from './stationStore';
import { Locales } from '../utils/enums/stationEnums';
import { createContext, useContext } from 'react';
import { AxiosError } from 'axios';
import Axious from '../services/api';

export class AuthStore {
  @observable private userRfid = '';
  @observable userName = '';
  @observable public managerId = '';
  @observable public authToken = '';
  @observable userLocale: Locales = Locales.English; // an employee can select his preffered locale
  @observable errorMessage = '';
  @observable loading = false;

  /**
   * @description returns if the user is logged in only in case the authToken, userRfid, and managerId exist
   */
  @computed get isLoggedIn() {
    return (
      Boolean(this.authToken) &&
      Boolean(this.userRfid) &&
      Boolean(this.managerId) &&
      Boolean(stationStore.stationId) &&
      Boolean(stationStore.stationType)
    );
  }

  /**
   * @description returns wether the system is authenticated by the manager
   */
  @computed get isManagerLoggedIn() {
    return Boolean(this.managerId);
  }

  /**
   * @description returns wether the system is validated by a manager, and that a station type and number has been selected
   */
  @computed get isSystemValidated() {
    return Boolean(this.managerId) && Boolean(stationStore.stationId) && Boolean(stationStore.stationType);
  }

  constructor() {
    this.userRfid = localStorage.getItem('userRfid') || '';
    this.userName = localStorage.getItem('userName') || '';
    this.managerId = localStorage.getItem('managerId') || '';
    this.authToken = localStorage.getItem('authToken') || '';
  }

  /**
   * @description clears the user data in store, however, it doesnt clear the manager id if it exists
   */
  @action.bound
  clearUser() {
    localStorage.clear();
  }

  @action.bound
  async login(userId: string, managerId = this.managerId, stationId = stationStore.stationId) {
    try {
      const response = await AuthService.login(userId, managerId, stationId);
      const { authToken, user } = response.data;
      runInAction(() => {
        this.authToken = authToken;
        this.userRfid = user.id;
        this.userName = user.name;
      });
    } catch (error) {
      if ((error as AxiosError).message) {
        runInAction(() => {
          this.errorMessage = error.message;
        });
      } else {
        this.errorMessage = error;
      }
    }
  }

  @action.bound
  logout() {
    // TODO: add api route for logged out
    AuthService.logout();
    this.userRfid = '';
    this.authToken = '';
    this.userName = '';
  }

  @action.bound
  async validateManager(managerId: string) {
    await AuthService.validateManagerId(managerId);
    this.setManagerId(managerId);
  }

  @action.bound
  async authenticateManager(managerId: string) {
    try {
      await AuthService.validateManagerId(managerId);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * @description sets the manager Id in store & local storage
   * @param managerId
   */
  @action.bound
  setManagerId(managerId: string) {
    this.managerId = managerId;
    Boolean(managerId) === true ? localStorage.setItem('managerId', managerId) : localStorage.removeItem('managerId');
  }

  /**
   * @description logs out from the system completly back to the manager RFID page
   */
  @action.bound
  resetSystem() {
    this.logout();
    this.setManagerId(''); // clears the manager Id as well
    stationStore.clearUserStations();
    // clear local storage
    localStorage.clear();
    stationStore.clearData();
  }

  @action.bound
  changeSystemLanguage(lang: Locales) {
    this.userLocale = lang;
  }
}

export const authStore = new AuthStore();

// autorun reacts to just everything that is used in its function
autorun(() => {
  if (authStore.isLoggedIn) {
    // add auth token to headers
    Axious.defaults.headers.common['Authorization'] = `Bearer ${authStore.authToken}`;
  } else {
    // remove axios auth token headre
    delete Axious.defaults.headers.common['Authorization'];
  }
});

const AuthStoreContext = createContext(authStore);

export const useAuthStore = () => useContext(AuthStoreContext);
