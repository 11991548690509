import React from 'react';
import { CustomDialog } from '.';
import { ScannerWidget } from '../scanner';
import { ScanType } from 'utils/enums/stationEnums';
import EmployeeCardImage from 'assets/images/pngs/employee-card.png';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { useAuthStore } from 'stores';
import { observer } from 'mobx-react-lite';
import { LoaderComponent } from '../loaderComponent';

interface DialogProps {
  open: boolean;
  autoValidate?: boolean;
  onManagerScan: (isValid: boolean, managerId?: string) => void;
  onClose: () => void;
  title?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContainer: {
      alignItems: 'stretch',
    },
  })
);

const ValidateManagerDialog: React.FC<DialogProps> = observer(
  ({ open, onClose, onManagerScan, autoValidate = true, title = 'Scan manager card to continue' }) => {
    const classes = useStyles();
    const { authenticateManager } = useAuthStore();
    const onCloseHandler = () => {
      onClose();
    };
    const handleScanComplete = async (value: string, type: ScanType) => {
      if (!autoValidate) {
        onManagerScan(false, value);
        return;
      }
      const isValid = await authenticateManager(value);
      onManagerScan(isValid);
    };
    return (
      <CustomDialog open={open} onClose={onCloseHandler} size="xl" title={title} classes={{ container: classes.dialogContainer }}>
        <LoaderComponent />
        <ScannerWidget border={false} onScanComplete={handleScanComplete} images={{ imageUrl: EmployeeCardImage, type: ScanType.RFID }} />
      </CustomDialog>
    );
  }
);

export default ValidateManagerDialog;
