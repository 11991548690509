import { IBag, Preferences, IOrder } from '../interfaces/stationInterfaces';
import { BagTypes } from '../enums/stationEnums';

export default class Bag implements IBag {
  addedItemCount: number = 0;
  bagCode: string = '';
  isScanned: boolean = false;
  isSorted: boolean = false;
  isTagged: boolean = false;
  orderAlphaId: string = '';
  orderId: string = '';
  reference: string = '';
  sortedItemCount: number = 0;
  type: BagTypes = BagTypes.CLEAN_PRESS;
  customerAlphaId: string = '';
  isCustomerVip?: boolean = false;
  customerId?: string;
  preferences?: Preferences;
  customerInstruction?: string;
  driverInstruction?: string;
  order: IOrder = {
    city: {
      city: '',
    },
    pickupDriver: {
      name: '',
    },
    isUrgent: false,
    orderAlphaId: '',
    geofence: {
      clusterName: '',
      label: '',
    },
    sortedCPBags: 0,
    sortedPBags: 0,
    sortedHCBags: 0,
    totalPBags: 0,
    totalCPBags: 0,
    totalHCBags: 0
  };

  constructor(customerId?: string, orderId?: string, bagType?: BagTypes) {
    this.bagCode = customerId && orderId ? `${customerId.toLowerCase()}${orderId.toLowerCase()}${Date.now()}` : '';
    this.orderAlphaId = orderId || '';
    this.customerAlphaId = customerId || '';
    this.type = bagType || BagTypes.CLEAN_PRESS;
  }
}
