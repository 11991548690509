import { ItemTypes } from '../interfaces';

/** Categories Types */
export interface ITypeObj {
  value: ItemTypes;
  label: string;
  iconName: string;
}

/** Main Object Type */
interface ITypeCategories {
  [key: string]: { [key: string]: ITypeObj };
}

export const itemTypeCategories = ['top', 'bottom', 'undergarment', 'linen', 'formal', 'ethnic', 'outerwear', 'other'];

// Return the TypeObject based on the program value property
export const typeObjHelper = (programKey: string) => {
  let programObj: ITypeObj = {} as ITypeObj;

  Object.keys(typeMapper).map((category: string) => {
    return Object.keys(typeMapper[category]).map((program: string) => {
      if (typeMapper[category][program].value === programKey) {
        return (programObj = typeMapper[category][program]);
      }
      return false;
    });
  });

  return programObj;
};

export const typeMapper: ITypeCategories = {
  top: {
    tShirt: {
      value: 'T_SHIRT',
      label: 'T-Shirt',
      iconName: 'tShirt',
    },
    shirt: {
      value: 'SHIRT',
      label: 'Shirt',
      iconName: 'shirt',
    },
    blouse: {
      value: 'BLOUSE',
      label: 'Blouse',
      iconName: 'blouse',
    },
    undershirt: {
      value: 'UNDERSHIRT',
      label: 'Undershirt',
      iconName: 'undershirt',
    },
  },
  outerwear: {
    sweater: {
      value: 'SWEATER',
      label: 'Sweater',
      iconName: 'sweater',
    },
    pullover: {
      value: 'PULLOVER',
      label: 'Pullover',
      iconName: 'pullover',
    },
    coat: {
      value: 'COAT',
      label: 'Coat',
      iconName: 'coat',
    },
    overcoat: {
      value: 'OVERCOAT',
      label: 'Overcoat',
      iconName: 'overcoat',
    },
    jacket: {
      value: 'JACKET',
      label: 'Jacket',
      iconName: 'jacket',
    },
  },
  bottom: {
    shorts: {
      value: 'SHORTS',
      label: 'Shorts',
      iconName: 'shorts',
    },
    pants: {
      value: 'PANTS',
      label: 'Pants',
      iconName: 'pants',
    },
    skirt: {
      value: 'SKIRT',
      label: 'Skirt',
      iconName: 'skirt',
    },
    jeans: {
      value: 'JEANS',
      label: 'Jeans',
      iconName: 'jeans',
    },
    pyjamaPants: {
      value: 'PYJAMA_PANTS',
      label: 'Pyjama Pants',
      iconName: 'pyjamaPants',
    },
  },
  ethnic: {
    kandura: {
      value: 'KANDURA',
      label: 'Kandura',
      iconName: 'kandura',
    },
    abaya: {
      value: 'ABAYA',
      label: 'Abaya',
      iconName: 'abaya',
    },
    gathra: {
      value: 'GATHRA',
      label: 'Gathra',
      iconName: 'gathra',
    },
    kurta: {
      value: 'KURTA_MENS',
      label: 'Kurta',
      iconName: 'kurta',
    },
    sari: {
      value: 'SARI',
      label: 'Sari',
      iconName: 'sari',
    },
    sherwani: {
      value: 'SHERWANI',
      label: 'Sherwani',
      iconName: 'sherwani',
    },
    sheila: {
      value: 'SHEILA',
      label: 'Sheila',
      iconName: 'sheila',
    },
    jalabiya: {
      value: 'JALABIYA',
      label: 'Jalabiya',
      iconName: 'jalabiya',
    },
    delicateAbaya: {
      value: 'DELICATE_ABAYA',
      label: 'Delicate Abaya',
      iconName: 'delicateAbaya',
    },
  },
  linen: {
    bedsheet: {
      value: 'BEDSHEET',
      label: 'Bedsheet',
      iconName: 'bedsheet',
    },
    pillowCase: {
      value: 'PILLOW_CASE',
      label: 'Pillow Case',
      iconName: 'pillowCase',
    },
    bathrobe: {
      value: 'BATHROBE',
      label: 'Bathrobe',
      iconName: 'bathrobe',
    },
    towel: {
      value: 'TOWEL',
      label: 'Towel',
      iconName: 'towel',
    },
    comforterCover: {
      value: 'COMFORTER_COVER',
      label: 'Comforter Cover',
      iconName: 'comforterCover',
    },
    tablemat: {
      value: 'TABLE_MAT',
      label: 'Table Mat',
      iconName: 'tablemat',
    },
    tableCloth: {
      value: 'TABLE_CLOTH',
      label: 'Table Cloth',
      iconName: 'tableCloth',
    },
    doormat: {
      value: 'DOORMAT',
      label: 'Floor Mat',
      iconName: 'doormat',
    },
    pillow: {
      value: 'PILLOW',
      label: 'Pillow',
      iconName: 'pillow',
    },
    blanketSmall: {
      value: 'BLANKET',
      label: 'Blanket',
      iconName: 'blanketSmall',
    },
    blanketLarge: {
      value: 'BLANKET_LARGE',
      label: 'Blanket Large',
      iconName: 'blanketLarge',
    },
    bedCover: {
      value: 'BEDCOVER',
      label: 'Bed Cover',
      iconName: 'bedCover',
    },
  },
  undergarment: {
    underwear: {
      value: 'UNDERWEAR',
      label: 'Man Underwear',
      iconName: 'underwear',
    },
    socks: {
      value: 'SOCKS',
      label: 'Socks',
      iconName: 'socks',
    },
    bra: {
      value: 'BRA',
      label: 'Bra',
      iconName: 'bra',
    },
  },
  formal: {
    suitPants: {
      value: 'SUIT_PANTS',
      label: 'Suits Pant',
      iconName: 'suitPants',
    },
    suitJacket: {
      value: 'SUIT_JACKET',
      label: 'Suit Jacket',
      iconName: 'suitJacket',
    },
    scarf: {
      value: 'SCARF',
      label: 'Scarf',
      iconName: 'scarf',
    },
    tie: {
      value: 'TIE',
      label: 'Tie',
      iconName: 'tie',
    },
    handkerchief: {
      value: 'HANDKERCHIEF',
      label: 'Handkerchief',
      iconName: 'handkerchief',
    },
    vest: {
      value: 'VEST',
      label: 'Vest',
      iconName: 'vest',
    },
    shortDress: {
      value: 'DRESS_SHORT',
      label: 'Short Dress',
      iconName: 'shortDress',
    },
    longDress: {
      value: 'DRESS_LONG',
      label: 'Long Dress',
      iconName: 'longDress',
    },
  },
  other: {
    cap: {
      value: 'CAP',
      label: 'Cap',
      iconName: 'cap',
    },
    jumpsuit: {
      value: 'JUMPSUIT',
      label: 'Jumpsuit',
      iconName: 'jumpsuit',
    },
    belt: {
      value: 'BELT',
      label: 'Belt',
      iconName: 'belt',
    },
    bathingSuit: {
      value: 'BATHING_SUIT',
      label: 'Bathing Suit',
      iconName: 'bathingSuit',
    },
    curtain: {
      value: 'CURTAIN_SQM',
      label: 'Curtain',
      iconName: 'curtain',
    },
    looseItem: {
      value: 'LOOSE_ITEM',
      label: 'Loose Item',
      iconName: 'looseItem',
    },
    accessory: {
      value: 'ACCESSORY',
      label: 'Accessory',
      iconName: 'accessory',
    },
    sofaCoverSmall: {
      value: 'SOFA_COVER_REGULAR',
      label: 'Sofa Cover Small',
      iconName: 'sofaCoverSmall',
    },
    sofaCoverLarge: {
      value: 'SOFA_COVER_LARGE',
      label: 'Sofa Cover Large',
      iconName: 'sofaCoverLarge',
    },
    cushionSmall: {
      value: 'CUSHION_COVER_SMALL',
      label: 'Cushion Small',
      iconName: 'cushionSmall',
    },
    cushionLarge: {
      value: 'CUSHION_COVER_LARGE',
      label: 'Cushion Cover Large',
      iconName: 'cushionLarge',
    },
    carpet: {
      value: 'CARPET_REGULAR_PERSQM',
      label: 'Carpet',
      iconName: 'carpet',
    },
    carpetWool: {
      value: 'CARPET_WOOL',
      label: 'Carpet Wool',
      iconName: 'carpetWool',
    },
    duffelBag: {
      value: 'DUFFEL_BAG',
      label: 'Duffel Bag',
      iconName: 'duffelBag',
    },
    backpack: {
      value: 'BACKPACK',
      label: 'Backpack',
      iconName: 'backpack',
    },
    duvet: {
      value: 'DUVET',
      label: 'Duvet',
      iconName: 'duvet',
    },
    comforter: {
      value: 'COMFORTER',
      label: 'Comforter',
      iconName: 'comforter',
    },
    glove:{
      value: 'GLOVE',
      label: 'Glove',
      iconName: 'glove',
    },
    faceMask:{
      value: 'FACE_MASK',
      label: 'Face Mask',
      iconName: 'faceMask',
    },

  },
};

export const commonItemTypes: any = {
  shirt: {
    value: 'SHIRT',
    label: 'Shirt',
    iconName: 'shirt',
  },
  tShirt: {
    value: 'T_SHIRT',
    label: 'T-Shirt',
    iconName: 'tShirt',
  },
  pants: {
    value: 'PANTS',
    label: 'Pants',
    iconName: 'pants',
  },
  blouse: {
    value: 'BLOUSE',
    label: 'Blouse',
    iconName: 'blouse',
  },
  pillowCase: {
    value: 'PILLOW_CASE',
    label: 'Pillow Case',
    iconName: 'pillowCase',
  },
  shortDress: {
    value: 'DRESS_SHORT',
    label: 'Short Dress',
    iconName: 'shortDress',
  },
  jacket: {
    value: 'JACKET',
    label: 'Jacket',
    iconName: 'jacket',
  },
  bedsheet: {
    value: 'BEDSHEET',
    label: 'Bedsheet',
    iconName: 'bedsheet',
  },
  suitJacket: {
    value: 'SUIT_JACKET',
    label: 'Suit Jacket',
    iconName: 'suitJacket',
  },
  shorts: {
    value: 'SHORTS',
    label: 'Shorts',
    iconName: 'shorts',
  },
  duvet: {
    value: 'DUVET',
    label: 'Duvet',
    iconName: 'duvet',
  },
};
