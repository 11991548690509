import React, { Suspense } from 'react';
import { observer } from 'mobx-react-lite';
import { Switch, Redirect, withRouter, RouteComponentProps } from 'react-router';

import { useStationStore } from 'stores';
import { OrderCompletedDialog } from 'components/dialogs';
import StationRoute from './stationRoute';
import { StationTypes } from 'utils/enums/stationEnums';
import { IOrderItem } from 'utils/interfaces';

// lazy import stations
const SorterStation = React.lazy(() => import('./sorterStation/sorterStation'));
const FoldingStation = React.lazy(() => import('./foldingStation/foldingStation'));
const TaggerStation = React.lazy(() => import('./taggerStation/taggerStation'));
const PressingStation = React.lazy(() => import('./pressingStation/pressingStation'));
const PackagingStation = React.lazy(() => import('./packagingStation/packagingStation'));
const WasherStation = React.lazy(() => import('./washerStation/washerStation'));
const ScannerStation = React.lazy(() => import('./scannerStation/scannerStation'));
const GrouperStation = React.lazy(() => import('./groupingStation/groupingStation'));
const RackingStation = React.lazy(() => import('./rackingStation/rackingStation'));

//  ========== ADD STATION ROUTES HERE ==============

const Stations: React.FC<{} & RouteComponentProps<{}>> = observer(({ match: { path }, history }) => {
  const { stationType, currentStation, setOrderItem } = useStationStore();

  const onOrderCompleteDialogCloseHandler = () => {
    if (currentStation === 'washer') {
      setOrderItem({} as IOrderItem);
      history.push(`../../`, path);
    }
  };

  return (
    <>
      <Suspense fallback={<div>Loading station...</div>}>
        <Switch>
          <StationRoute path={`${path}/${StationTypes.Folding}`} component={FoldingStation} />
          <StationRoute path={`${path}/${StationTypes.FoldingLinen}`} component={FoldingStation} />
          <StationRoute path={`${path}/${StationTypes.Tagger}`} component={TaggerStation} />
          <StationRoute path={`${path}/${StationTypes.ShirtPressing}`} component={PressingStation} />
          <StationRoute path={`${path}/${StationTypes.MultiPressing}`} component={PressingStation} />
          <StationRoute path={`${path}/${StationTypes.CollarAndCuffPressing}`} component={PressingStation} />
          <StationRoute path={`${path}/${StationTypes.PantsTopper}`} component={PressingStation} />
          <StationRoute path={`${path}/${StationTypes.Sorter}`} component={SorterStation} />
          <StationRoute path={`${path}/${StationTypes.Scanner}`} component={ScannerStation} />
          <StationRoute path={`${path}/${StationTypes.BannedPackaging}`} component={PackagingStation} />
          <StationRoute path={`${path}/${StationTypes.Washer}`} component={WasherStation} />
          <StationRoute path={`${path}/${StationTypes.HandWash}`} component={WasherStation} />
          <StationRoute path={`${path}/${StationTypes.Grouping}`} component={GrouperStation} />
          <StationRoute path={`${path}/${StationTypes.Racking}`} component={RackingStation} />
          <StationRoute path={`${path}/${StationTypes.QualityControl}`} component={WasherStation} />
          <StationRoute path={`${path}/${StationTypes.Spotter}`} component={WasherStation} />
          <StationRoute path={`${path}/${StationTypes.Tailor}`} component={WasherStation} />
          <Redirect to={`${path}/${stationType}`} />
        </Switch>
      </Suspense>
      <OrderCompletedDialog onClose={onOrderCompleteDialogCloseHandler} />
    </>
  );
});

export default withRouter(Stations);
