import axios, { AxiosInstance } from 'axios';
import { responseHandler } from '../utils/api/handle-response';
import { errorHandler } from '../utils/api/handle-error';
import { uiState } from '../stores';


const { REACT_APP_INTERNAL_API_HOST, REACT_APP_DEV_SERVER, REACT_APP_MOCKED } = process.env;
const baseURL = REACT_APP_MOCKED ? REACT_APP_DEV_SERVER : REACT_APP_INTERNAL_API_HOST;

const Axious: AxiosInstance = axios.create({
  baseURL,
  headers: { 'Content-Type': 'application/json' },
  timeout: 10000,
  withCredentials: false,
});


// Add a request interceptor
Axious.interceptors.request.use(
  function (config) {
    uiState.setIsLoading(true);
    // Do something before request is sent
    const d = new Date();
    config.headers.common['user-timestamp'] = d.getTime();
    return config;
  },
  function (error) {
    // Do something with request error
    uiState.setIsLoading(false);
    console.log(error.response.status, '---');
    return Promise.reject(error);
  }
);

// Add a response interceptor
Axious.interceptors.response.use(responseHandler, errorHandler);

export default Axious;
