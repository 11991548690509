import React from 'react';
import { Typography, Grid, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';

import { IOrderItem } from 'utils/interfaces';
import { CustomDialog } from '../dialogs';
import { BrandDisplay } from 'components/brandDisplay';
import { ColorMapper } from 'components/colorMapper';

interface Props {
  onClose: () => void;
  open: boolean;
  children: any;
  typeIcons?: string;
  sizing?: 'lg' | 'md' | 'sm';
  item: IOrderItem;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    borderBottom: {
      borderBottom: '.1rem solid #b6bcd1',
      padding: '1rem 3rem .2rem 1rem',
    },
    padding: {
      padding: '1rem',
    },
    orderNo: {
      paddingTop: '.5rem',
    },
  })
);
const HeaderDialog: React.FC<Props> = ({ onClose, open, children, typeIcons, sizing = 'md', item }) => {
  const classes = useStyles();

  return (
    <>
      <CustomDialog onClose={onClose} open={open} size={sizing} disableAutoFocus={true}>
        {item && (
          <Grid container direction="column">
            <Grid container direction="row-reverse" alignItems="flex-start" className={classes.borderBottom}>
              <Grid item xs={'auto'}>
                {item.brand && <BrandDisplay brand={item!.brand} />}
              </Grid>
              {item.colorsList && (
                <Grid item xs={'auto'}>
                  <ColorMapper colorsList={item.colorsList} dimension="3rem" textHidden />
                </Grid>
              )}
              <Grid item xs={'auto'}>
                {typeIcons && <img src={typeIcons} style={{ height: '4.5rem' }} alt="typeIcon" />}
              </Grid>
              <Grid item xs={true}>
                <Typography variant="h4" className={classes.orderNo}>
                  Item No. {item.code ? item.code : item.itemCode}
                </Typography>
              </Grid>
            </Grid>
            {children}
          </Grid>
        )}
      </CustomDialog>
    </>
  );
};
export default HeaderDialog;
