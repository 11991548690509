import React from 'react';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router';
import { observer } from 'mobx-react-lite';

import { useAuthStore } from 'stores';
import SelectStation from './selectStation';
import { ScannerWidget } from 'components/scanner';
import { ScanType } from 'utils/enums/stationEnums';

import EmployeeCardImage from 'assets/images/pngs/employee-card.png';

const Login: React.FC<{} & RouteComponentProps<{}>> = observer(({ match: { path }, history }) => {
  const { isSystemValidated, isManagerLoggedIn, login, validateManager } = useAuthStore();

  const onManagerScanComplete = async (rfidCode: string) => {
    await validateManager(rfidCode);
    history.push(`${path}/manager/selectStation/section`);
  };

  const onEmployeeScanComplete = (rfidCode: string) => {
    login(rfidCode);
  };

  return (
    <Switch>
      <Route
        exact
        path={`${path}/manager`}
        render={(props) => {
          if (isSystemValidated) {
            return <Redirect to={`${path}/user`} />;
          } else {
            // TODO: clear stationtype and station Id before redicting
            return (
              <ScannerWidget
                title="login.scanRfidManager"
                onScanComplete={onManagerScanComplete}
                images={{
                  imageUrl: EmployeeCardImage,
                  type: ScanType.RFID,
                }}
              />
            );
          }
        }}
      />
      <Route
        exact
        path={`${path}/manager/selectStation/:type(number|type|section)?/`}
        render={(props) =>
          isSystemValidated === true ? (
            <Redirect to={`${path}/user`} />
          ) : isManagerLoggedIn === true ? (
            <SelectStation />
          ) : (
            <Redirect to={`${path}/manager`} />
          )
        }
      />
      <Route
        exact
        path={`${path}/user`}
        render={(props) =>
          isSystemValidated ? (
            <ScannerWidget
              title="login.scanRfidEmployee"
              onScanComplete={onEmployeeScanComplete}
              images={{
                imageUrl: EmployeeCardImage,
                type: ScanType.RFID,
              }}
            />
          ) : (
            <Redirect to={`${path}/manager`} />
          )
        }
      />
      <Redirect to={`${path}/manager`} />
    </Switch>
  );
});

export default Login;
