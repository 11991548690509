import BlueBag from 'assets/images/pngs/bags/blue-bag-inactive.png';
import GreenBag from 'assets/images/pngs/bags/green-bag-inactive@3x.png';
import WhiteBag from 'assets/images/pngs/bags/white-bag-inactive@3x.png';
import PinkBag from 'assets/images/pngs/bags/pink-bag.png';

const BagTypeMap: { [key: string]: string } = {
  CP: GreenBag,
  WF: BlueBag,
  P: WhiteBag,
  HC: PinkBag,
};

export default BagTypeMap;
