import Axious from './api';
import { AxiosRequestConfig } from 'axios';
import { IPackageResponse, IPackage, Order } from '../utils/interfaces';
import { ParserService } from '.';
import { IZplStickerObject } from 'services/parser.service';
import { IFoldingPackageOrder } from 'stores/foldingStore'

class PackagingService {
  getPackageItem = async (itemsCodesList: string[], orderId: string, packageType: string) => {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      url: `items/banned/package`,
      data: {
        itemsCodesList: itemsCodesList,
        orderId: orderId,
      },
    };

    const {
      data: {
        packageId: { packages, order },
      },
    } = await Axious.request<IPackageResponse>(requestOptions);

    // Todo: test on banned
    const parserObj = zplStickerParser(packages[0], order);

    return parserObj;
  };

  fetchItemsAndPrint = async (itemsCodesList: string[], orderId: string, packageType: string) => {
    const response = await this.getPackageItem(itemsCodesList, orderId, packageType);
    const parsedObject = ParserService.generateZplSticker(response);
    ParserService.clearZplInstance();
    return {
      parsedObject,
      packageSerialId: response.packageSerialId,
    };
  };
}

const packagingService = new PackagingService();

// export as a singelton
export default packagingService;


export const zplStickerParser = (currentPackage: IPackage, order: Order | IFoldingPackageOrder) => {

  const { itemsList, packageCode } = currentPackage;
  const {
    orderAlphaId,
    pickupDate,
    dropoffDate,
    dropoffAddress: { building, villa, number, geofence },
  } = order;


  const parserObj: IZplStickerObject = {
    orderAlphaId: orderAlphaId,
    addressBldg: `${building ? building : villa ? villa : ''}${number ? ` - ${number}` : ''}`,
    fullAddress: geofence && geofence.label ? geofence.label : 'cluster none',
    pickupDate: pickupDate,
    dropOffDate: dropoffDate,
    packageSerialId: packageCode,
    itemsList,
  };
  return parserObj;
}