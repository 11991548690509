import React from 'react';
import { createContext, useContext } from 'react';
import { observable, action, computed } from 'mobx';

import { IOrderItem, IOrder, PickupAddress, IBag } from 'utils/interfaces';
import { StationService } from 'services';
import { FoldingType } from 'utils/enums/stationEnums';
import { toast } from 'react-toastify';
import { Toaster } from 'components/toaster';

/** ----- Interfaces ----- */
export interface IFoldingBag {
  bagCode: string;
  bagType: string;
  orderId: string;
  isSorted: boolean;
  items: IOrderItem[];
}
export interface IPackageInfo {
  assignedFoldingRack: string;
  isFirstPackageInBag?: boolean;
  isLastPackageInBag?: boolean;
  itemsList: IOrderItem[];
  numberOfFoldingPackages: number;
  packageCode: string;
}

export interface IFoldingPackageOrder extends IOrder {
  customerAlphaId: string;
  dropoffAddress: PickupAddress;
  dropoffDate: string;
  isConsolidated: boolean;
  isUpdateApprovalExpired: boolean;
  orderAlphaId: string;
  pickupDate: string;
  totalFoldedBags: number;
  totalRemainingBagsForFolding: number;
  building?: string;
  number?: string;
  villa?: string;
}

interface IRemainingItems {
  bag: { bagCode: string; bagType: 'WF' | 'CP' | 'P'; orderId: string };
  orderItemsList: IOrderItem[];
}

interface IFoldingOrder {
  item: IOrderItem;
  remainingItemsInBag: IRemainingItems;
  remainingItemsForPackage: IRemainingItems;
}

export interface IFoldingPackage {
  order: IFoldingPackageOrder;
  packageType: FoldingType;
  packages: IPackageInfo[];
  bag: IBag;
}

export enum ESidebarState {
  scanner = 'SCANNER',
  package = 'PACKAGE_RFID',
  unpackage = 'UNPACKAGE_RFID',
}

class FoldingState {
  /** ----- OBSERVABLE ----- */
  @observable orderItem: IOrderItem = {} as IOrderItem;
  @observable packageObj: IFoldingPackage = {} as IFoldingPackage;
  @observable selectedItem: IOrderItem = {} as IOrderItem;
  @observable scannedItems: IOrderItem[] = [];
  @observable remainingItems: IOrderItem[] = [];
  @observable itemsInPackage: IOrderItem[] = [];
  @observable sideBarAction: ESidebarState = ESidebarState.scanner;
  @observable itemsInBag: IOrderItem[] = [];
  @observable isItemsListOpen: boolean = false;
  @observable orderOnHold: IFoldingOrder | undefined = undefined;

  /** ----- COMPUTED ----- */

  @computed
  get itemFoldingType() {
    return this.orderItem.packagingDetails && this.orderItem.packagingDetails.packageCategory;
  }

  @computed.struct
  get isPackageReady() {
    const { packages } = this.packageObj;
    return packages && packages.length > 0 && (packages[packages.length - 1].isLastPackageInBag || false);
  }

  @computed.struct
  get isOrderReady() {
    return this.packageObj && this.packageObj.order && this.packageObj.order.totalRemainingBagsForFolding <= 0;
  }

  @computed
  get rackNumber() {
    const { packages } = this.packageObj;
    return packages && packages[packages.length - 1].assignedFoldingRack ? packages[packages.length - 1].assignedFoldingRack.split('/')[1] : -1;
  }

  @computed.struct
  get addPackageDisabled() {
    return this.itemsList.length <= 0;
  }

  @computed.struct
  get currentPackageInfo() {
    if (this.packageObj && this.packageObj.packages) {
      return this.packageObj.packages[this.packageObj.packages.length - 1];
    }
    return undefined;
  }

  @computed.struct
  get itemsList() {
    if (this.orderItem && this.orderItem.packagingDetails) {
      const {
        packagingDetails: { packageCategory },
      } = this.orderItem;

      const isScannerMode = this.sideBarAction === ESidebarState.scanner;
      const isUnpackageMode = this.sideBarAction === ESidebarState.unpackage;

      const isTransit = packageCategory && packageCategory === FoldingType.transit;
      const isMulti = packageCategory && packageCategory === FoldingType.multiple;

      // Scanner Mode
      if (isScannerMode) {
        if (isTransit || isMulti) {
          // Item that is *not* scanned yet
          const notScannedItems = this.remainingItems.filter((item: IOrderItem) => {
            return !this.scannedItems.some((scannedItem: IOrderItem) => scannedItem.code === item.code);
          });

          // Double check that no packaged item will appear
          return [...this.scannedItems, ...notScannedItems].filter((item: IOrderItem) => !item.isPackaged);
        }
        return this.scannedItems;
      }
      // Unpackaging Mode
      if (isUnpackageMode) {
        return this.itemsInPackage;
      }

      // Packaging Mode
      if (isTransit) {
        // Item that is *not* packaged yet
        const notPackagedItem = this.scannedItems.filter((item: IOrderItem) => {
          return !this.itemsInPackage.some((addedItem: IOrderItem) => addedItem.code === item.code);
        });

        // Return the items in order
        return [...this.itemsInPackage, ...notPackagedItem].filter((item: IOrderItem) => !item.isPackaged);
      }

      return this.itemsInPackage;
    }
    return [];
  }

  @computed
  get multiRemainingItems() {
    const _isScanned = (itemCode: string) => {
      return this.scannedItems.some((item) => item.code === itemCode);
    };

    return this.remainingItems.filter((item: IOrderItem) => {
      return !_isScanned(item.code) && !item.isPackaged;
    });
  }

  @computed.struct
  get scannedItemCount() {
    return this.itemsList.length;
  }

  @computed.struct
  get isFirstPackage() {
    return (
      this.packageObj &&
      this.packageObj.packages &&
      this.packageObj.packages.length > 0 &&
      this.packageObj.packages[this.packageObj.packages.length - 1].isFirstPackageInBag &&
      !this.packageObj.packages[this.packageObj.packages.length - 1].isLastPackageInBag
    );
  }

  @computed
  get isAllItemsScanned() {
    if (this.sideBarAction === ESidebarState.package) {
      return this.itemsList.length === this.itemsInPackage.length;
    }

    return false;
  }

  /** ----- SETTER ----- */

  @action
  setOrderItem = (item: IOrderItem) => {
    this.orderItem = item;
  };

  @action
  setScannedItems = (items: IOrderItem[]) => {
    //Empty this array in memory
    this.scannedItems.length = 0;
    this.scannedItems = [...items];
  };

  @action
  addToScannedItems = (item: IOrderItem) => {
    this.scannedItems.push(item);
    this.scannedItems.map((arrayItem: IOrderItem) => {
      if (arrayItem.code === item.code) return item;
      return arrayItem;
    });
  };

  @action
  setRemainingItems = (items: IOrderItem[]) => {
    this.remainingItems = [...items];
  };

  @action
  setItemsInBag = (items: IOrderItem[]) => {
    this.itemsInBag = [...items];
  };

  @action
  setItemsInPackage = (items: IOrderItem[]) => {
    this.itemsInPackage.length = 0;
    this.itemsInPackage = [...items];
  };

  @action
  setPackageObj = (packageObj: IFoldingPackage) => {
    this.packageObj = { ...packageObj };
  };

  @action.bound
  setSidebarAction(state: ESidebarState) {
    this.sideBarAction = state;
  }

  @action.bound
  addToPackage(item: IOrderItem) {
    this.itemsInPackage.push(item);
  }

  @action.bound
  setIsItemsListOpen() {
    this.isItemsListOpen = !this.isItemsListOpen;
  }

  @action.bound
  setSelectedItem(item: IOrderItem) {
    this.selectedItem = { ...item };
  }

  @action.bound
  setOrderOnHold(order: IFoldingOrder | undefined) {
    // If the order is defined set it
    if (order) {
      return (this.orderOnHold = { ...order });
    }
    // If not clear it
    // Easy! It's not rocket science 🚀
    this.orderOnHold = undefined;
  }

  /**
   * @description Setup a new order
   *
   * @param {IOrderItem} item
   * @param {IRemainingItems} remainingItemsInBag
   * @param {IRemainingItems} remainingItemsForPackage
   *
   * @example initOrder(item, remainingItemsInBag, remainingItemsForPackage)
   */
  initOrder = (item: IOrderItem, remainingItemsInBag: IRemainingItems, remainingItemsForPackage: IRemainingItems) => {
    // If Item already package return error (Should be redirected to the unpackaging flow )

    // Make sure the item is Sorted and contains the packagingDetail
    if (item.packagingDetails) {
      const {
        packagingDetails: { packageCategory },
      } = item;

      this.setOrderItem(item);
      this.setSelectedItem(item);

      if (item.packagingDetails && packageCategory) {
        if (packageCategory === FoldingType.transit) {
          // If the transit item has already been scanned, alert the user and continue the flow
          if (item.isScannedOnFolding) if (!item.isPackaged) toast(<Toaster message="Item already Scanned!" type="error" />);

          // Make sure that the item have remaing items for folding
          if (remainingItemsForPackage.orderItemsList.length > 0) {
            // This is only applicable to the transit flow, because we have the isScannedOnFolding flag !
            // Filter scanned items
            const alreadyScanned: IOrderItem[] = remainingItemsForPackage.orderItemsList.filter((item: IOrderItem) => {
              return item.isScannedOnFolding;
            });
            // Filter item that has not been scanned on the folding station
            const notScannedItem = remainingItemsForPackage.orderItemsList.filter((item: IOrderItem) => !item.isScannedOnFolding);

            // Update the store values
            this.setRemainingItems(notScannedItem);
            this.setScannedItems(alreadyScanned);
          }
        } else if (packageCategory === FoldingType.multiple) {
          // Make sure that the item have remaing items for folding
          if (remainingItemsForPackage.orderItemsList.length > 0) {
            // Update the store values
            this.setRemainingItems(remainingItemsForPackage.orderItemsList);
            this.setScannedItems([item]);
          }
        } else {
          // No remaining item logic for single/MP items
          // Set the orderItem to the scannedItems Array
          this.setScannedItems([item]);
        }
      }
      // Fill in the item in bag
      if (remainingItemsInBag && remainingItemsInBag.orderItemsList) this.setItemsInBag(remainingItemsInBag.orderItemsList);
    }
  };

  /**
   * @description Handle the multi-scanner action
   *
   * @param {ScanType} scanType
   * @param {string} value
   *
   * @example handleItemScan('rfid', 'e2004354356878789'))
   */
  @action
  handleItemScan = async (value: string) => {
    try {
      const response = await StationService.fetchFoldingItem(value);

      const { item, remainingItemsInBag, remainingItemsForPackage } = response;

      if (item) {
        this.initOrder(item, remainingItemsInBag, remainingItemsForPackage);
        if (item.isPackaged) {
          const { rfid } = this.orderItem;
          if (rfid) {
            const packageResponse = await StationService.getPackageDetail(rfid);
            this.setPackageObj(packageResponse);
          }
        }
        return item;
      }
      // throw Error();
    } catch (err) {
      //Handle the error with a toast
      console.log(err);
    }
  };

  /**
   * @description Add items for multiple items
   *
   * @param {string} identifier - Item code
   * @param {boolean} isManual - true, if it's a manual scan
   *
   * @example onAddItem('12345678', 'rfid')
   */
  @action.bound
  onAddItem = async (identifier: string, isManual: boolean = false) => {
    try {
      const response = await StationService.fetchFoldingItem(identifier, isManual);

      const { item, remainingItemsInBag } = response;

      if (item.isPackaged) return toast(<Toaster message="Item already packaged!" type="error" />);

      // Skip Duplicates
      if (this.itemsInPackage.some((packagedItem: IOrderItem) => packagedItem.code === identifier || packagedItem.rfid === identifier)) {
        // Select the item in the sidebar
        this.setSelectedItem(item);
        // Warn the user
        return toast(<Toaster message="Item already added!" type="error" />);
      }

      // Check if the item belongs to the same order
      if (item && item.orderAlphaId !== this.orderItem.orderAlphaId) {
        // Save the response in a temp variable
        this.setOrderOnHold(response);
        return { differentOrderError: true };
      }

      // Trigger modal on Hung Item
      if (item && item.packagingDetails && item.packagingDetails.packageType === 'HUNG') return { item, isHungItem: true };

      //Update items in bag
      if (item.bagCode === this.orderItem.bagCode && remainingItemsInBag && remainingItemsInBag.orderItemsList) {
        this.setItemsInBag(remainingItemsInBag.orderItemsList);
      } else {
        // When adding items from a different bag, add them over the old items
        this.setItemsInBag([...this.itemsInBag, item]);
      }

      // Proceed with the scanned item
      this.onAddItemConfirmed(item);
    } catch (e) {
      return e;
    }
  };

  /**
   * @description Handle the item addition
   *
   * @param {IOrderItem} item
   *
   * @example onAddItemConfirmed()
   */
  @action.bound
  onAddItemConfirmed = (item: IOrderItem) => {
    const packagingMode = this.sideBarAction === ESidebarState.package;
    if (packagingMode) {
      this.addToPackage(item);
    }

    if (item.packagingDetails && item.packagingDetails.packageCategory === FoldingType.transit) {
      // Add the item to the scanned Item list
      this.addToScannedItems({ ...item, isScannedOnFolding: true });
      // Select the item in the sidebar
      return this.setSelectedItem({ ...item, isScannedOnFolding: true });
    }

    // Add the item to the scanned Item list
    this.addToScannedItems(item);
    // Select the item in the sidebar
    this.setSelectedItem(item);
  };

  /**
   * @description Handles the scan and remove RFID tag action (Transit Flow)
   *
   * @param {string} identifier - item identifier(QR/RFID)
   * @param {boolean} isManual - if item scanned manually
   *
   * @example onScanAndRemove()
   */
  @action
  onScanAndRemove = async (identifier: string, isManual: boolean = false) => {
    try {
      const response = await StationService.fetchFoldingItem(identifier, isManual);

      const { item, remainingItemsInBag } = response;

      if (item.isPackaged) return toast(<Toaster message="Item already packaged!" type="error" />);

      // Check if the item belongs to the same order
      if (item && item.orderAlphaId !== this.orderItem.orderAlphaId) {
        // Save the response in a temp variable
        this.setOrderOnHold(response);
        return { differentOrderError: true };
      }

      if (this.itemsInPackage.some((packagedItem: IOrderItem) => packagedItem.code === identifier || packagedItem.rfid === identifier))
        return toast(<Toaster message="Item already added!" type="error" />);

      const _isScanned = (itemCode: string) => {
        return this.scannedItems.some((item) => item.code === itemCode);
      };

      // Trigger modal on Hung Item
      if (item && item.packagingDetails && item.packagingDetails.packageType === 'HUNG' && !_isScanned(item.code)) return { item, isHungItem: true };

      //Update items in bag
      if (item.bagCode === this.orderItem.bagCode && remainingItemsInBag && remainingItemsInBag.orderItemsList) {
        this.setItemsInBag(remainingItemsInBag.orderItemsList);
      } else {
        // When adding items from a different bag, add them over the old items
        this.setItemsInBag([...this.itemsInBag, item]);
      }

      // Add item to package directly
      if (_isScanned(item.code)) {
        this.addToPackage(item);
      } else {
        this.onAddItemConfirmed(item);
      }

      // Return item maybe needed(?)
      return response;
    } catch (e) {
      return e;
    }
  };

  /**
   * @description Handle the changes to a new order
   *
   * @example goToNewOrder()
   */
  @action
  goToNewOrder = () => {
    if (this.orderOnHold) {
      const { item, remainingItemsInBag, remainingItemsForPackage } = this.orderOnHold;
      this.clearData();

      this.initOrder(item, remainingItemsInBag, remainingItemsForPackage);

      this.setOrderOnHold(undefined);
    }
  };

  /**
   * @description Fetch the remaing transit items per bag
   *
   * @param {string} bagCode
   *
   * @example fetchRemainingTransitItems('1234567')
   */
  @action.bound
  fetchRemainingTransitItems = async (bagCode: string) => {
    try {
      if (this.orderItem && this.orderItem.packagingDetails) {
        const { bagWithOrdertems } = await StationService.fetchTransitItems(bagCode, this.orderItem.packagingDetails.packageCategory);
        if (bagWithOrdertems.orderItemsList.length > 0) {
          const itemsRemainingArray = bagWithOrdertems.orderItemsList.filter((item: IOrderItem) => !item.isScannedOnFolding);
          const itemsScannedArray = bagWithOrdertems.orderItemsList.filter((item: IOrderItem) => item.isScannedOnFolding);

          this.setRemainingItems(itemsRemainingArray);
          this.setScannedItems(itemsScannedArray);

          return itemsRemainingArray;
        }
      }
    } catch (e) {
      return e;
    }
  };

  /**
   * @description Fetch remaing multi items remaining per lot(package)
   *
   * @example fetchMultiFoldingItems()
   */
  @action
  fetchMultiFoldingItems = async () => {
    try {
      if (this.orderItem && this.orderItem.packagingDetails) {
        //TODO: Handle in case no packageCode
        if (this.orderItem.packageCode) {
          const data = await StationService.fetchMultiFoldingItems(this.orderItem.packageCode);
          if (data.orderItemsList && data.orderItemsList.length > 0) {
            // Add condition in the compute to remove the scannedItems from the remainingItemsArray
            const itemsArray = data.orderItemsList.splice(0, 3);
            this.setRemainingItems(itemsArray);

            return itemsArray;
          }
        }
      }
    } catch (e) {
      return e;
    }
  };

  /**
   * @description Fetch the folding/hung items in bag
   *
   * @example fetchItemsInBag()
   */
  @action
  fetchItemsInBag = async () => {
    try {
      if (this.orderItem && this.orderItem.bagCode) {
        const response = await StationService.fetchTransitItems(this.orderItem.bagCode, 'ALL');

        if (response && response.bagWithOrdertems && response.bagWithOrdertems.orderItemsList)
          this.setItemsInBag(response.bagWithOrdertems.orderItemsList);
      }
    } catch (e) {}
  };

  /**
   * @description Handle on Add package, remove packaged items from scannedItems
   *
   * @example onAddPackage()
   */
  @action
  onAddPackage = async () => {
    if (this.packageObj && this.packageObj.packages) {
      const packagedItems = this.packageObj.packages.map((packet: IPackageInfo) => packet.itemsList).flat(1);
      const codesList = packagedItems.map((item: IOrderItem) => item.code);
      const remainingItems = this.scannedItems.filter((item: IOrderItem) => {
        const index = codesList.indexOf(item.code);
        if (index === 0) {
          return false;
        }
        return true;
      });

      await this.fetchItemsInBag();
      this.setScannedItems(remainingItems);
      this.setItemsInPackage([]);
    }
  };

  /**
   * @description On Complete and submit package
   *
   * @example onCompletePackage()
   */
  @action
  onCompletePackage = async () => {
    try {
      const { orderId, packagingDetails, bagCode } = this.orderItem;
      const qrCodeList = this.itemsInPackage.map((item: IOrderItem) => item.code);

      if (qrCodeList.length === 0) return toast(<Toaster message="No items in package" type="error" />);

      if (packagingDetails && packagingDetails.packageCategory) {
        const packageResponse = await StationService.completeFoldingPackage(orderId, bagCode, packagingDetails.packageCategory, qrCodeList);

        this.setPackageObj(packageResponse.packagingDetails);

        if (this.itemFoldingType !== FoldingType.single) {
          // Update the list of remaining Scanned Items (this is used on the addPackage logic)
          const newScannedItems = this.scannedItems.filter((item: IOrderItem) => {
            return qrCodeList.indexOf(item.code) === -1 && !item.isPackaged;
          });

          this.setScannedItems(newScannedItems);
        }

        // Prepare the new package
        await this.onAddPackage();

        return packageResponse.packagingDetails;
      }
    } catch (e) {
      return e;
    }
  };

  @action
  onPrintPackageLabel = async () => {
    try {
      const { orderId, packagingDetails, bagCode } = this.orderItem;
      const qrCodeList = this.itemsInPackage.map((item: IOrderItem) => item.code);

      if (qrCodeList.length === 0) return toast(<Toaster message="No items in package" type="error" />);

      if (packagingDetails && packagingDetails.packageCategory) {
        const packageResponse = await StationService.completeFoldingPackage(orderId, bagCode, packagingDetails.packageCategory, qrCodeList);

        this.setPackageObj(packageResponse.packagingDetails);
        return packageResponse.packagingDetails;
      }
    } catch (e) {
      return e;
    }
  };

  @action
  onFinishPackage = async () => {
    if (this.itemFoldingType !== FoldingType.single) {
      const qrCodeList = this.itemsInPackage.map((item: IOrderItem) => item.code);

      // Update the list of remaining Scanned Items (this is used on the addPackage logic)
      const newScannedItems = this.scannedItems.filter((item: IOrderItem) => {
        return qrCodeList.indexOf(item.code) === -1 && !item.isPackaged;
      });

      this.setScannedItems(newScannedItems);
    }

    // Prepare the new package
    await this.onAddPackage();
  };
  /**
   * @description unpackages items and deletes the links
   * associated with that package
   *
   * @param {string} packageId
   *
   * @example onFinishUnpackaging (item)
   */
  @action onFinishUnpackaging = async (packageId: string) => {
    const packageResponse = await StationService.onFinishUnpackaging(packageId);
    return packageResponse;
  };

  /**
   * @description Remove an item from package (FullScreen bag overview)
   *
   * @param {string} rfid - item rfid
   *
   * @example onRemoveItem()
   */
  @action.bound
  onRemoveItem = async (rfid: string) => {
    // Log Activity
    await StationService.cancelItemOnFolding(rfid);

    if (this.itemsInPackage.length === 1) {
      this.setItemsInPackage([]);
      return [];
    }

    // Remove item from package
    const updatedPackagedItems = this.itemsInPackage.filter((item: IOrderItem) => item.rfid !== rfid);
    const undateScannedItems = this.scannedItems.filter((item: IOrderItem) => item.rfid !== rfid);
    const updateRemainingItems = this.remainingItems.filter((item: IOrderItem) => item.rfid !== rfid);

    // Set the updated list of items in package
    this.setItemsInPackage(updatedPackagedItems);
    this.setRemainingItems(updateRemainingItems);
    this.setScannedItems(undateScannedItems);

    // return the update list
    return updatedPackagedItems;
  };

  /**
   * @description Clear store data
   *
   * @example clearData()
   */
  @action.bound
  clearData = () => {
    this.orderItem = {} as IOrderItem;
    this.packageObj = {} as IFoldingPackage;
    this.sideBarAction = ESidebarState.scanner;
    this.scannedItems.length = 0;
    this.itemsInBag.length = 0;
    this.remainingItems.length = 0;
    this.itemsInPackage.length = 0;
  };
}

export const foldingState = new FoldingState();
const FoldingStateContext = createContext(foldingState);

export const useFoldingStore = () => useContext(FoldingStateContext);
