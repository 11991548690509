import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Switch, Route, RouteComponentProps, withRouter } from 'react-router';

import { useStationStore } from 'stores';
import { item, SelectStationItemList } from 'components/selectStationItemList';
import { StationTypes } from 'utils/enums/stationEnums';

interface IStationNumbers {
  [key: string]: any;
}
const stationSections = [
  {
    i18nKey: 'section.receiving',
    text: 'Receiving',
    value: 'receiving',
    items: [
      {
        i18nKey: 'station.grouping',
        text: 'Grouping',
        value: 'grouping',
      },
    ],
  },
  {
    i18nKey: 'section.cleaning',
    text: 'Cleaning',
    value: 'cleaning',
    items: [
      {
        i18nKey: 'station.washer',
        text: 'Washer',
        value: 'washer',
      },
      {
        i18nKey: 'station.spotter',
        text: 'Spotter',
        value: 'spotter',
      },
      {
        i18nKey: 'station.handWash',
        text: 'Hand Wash',
        value: 'hand-wash',
      },
    ],
  },
  {
    i18nKey: 'section.tailor',
    text: 'Tailor',
    value: 'tailor',
    items: [
      {
        i18nKey: 'station.tailor',
        text: 'Tailor',
        value: 'tailor',
      },
    ],
  },
  {
    i18nKey: 'section.pressing',
    text: 'Pressing',
    value: 'pressing',
    items: [
      {
        i18nKey: 'station.shirtPressing',
        text: 'Shirt Pressing',
        value: 'shirt-pressing',
      },
      {
        i18nKey: 'station.multiPressing',
        text: 'Multi Pressing',
        value: 'multi-pressing',
      },
      {
        i18nKey: 'station.collarAndCuffPressing',
        text: 'Collar & Cuff Pressing',
        value: 'collar-cuff-pressing',
      },
      {
        i18nKey: 'station.pantsTopper',
        text: 'Pants Topper',
        value: 'pants-topper',
      },
    ],
  },
  {
    i18nKey: 'section.dispatch',
    text: 'Dispatch',
    value: 'dispatch',
    items: [
      {
        i18nKey: 'station.folding',
        text: 'Folding',
        value: 'folding',
      },
      {
        i18nKey: 'station.bannedPackaging',
        text: 'Banned Packaging',
        value: 'banned-packaging',
      },

      {
        i18nKey: 'station.scanner',
        text: 'Scanner',
        value: 'scanner',
      },

      {
        i18nKey: 'station.racking',
        text: 'Racking',
        value: 'racking',
      },
    ],
  },
  {
    i18nKey: 'section.qualityControl',
    text: 'Quality Control',
    value: 'quality-control',
    items: [
      {
        i18nKey: 'station.qualityControl',
        text: 'Quality Control',
        value: 'quality-control',
      },
      {
        i18nKey: 'station.washer',
        text: 'Washer',
        value: 'washer',
      },
    ],
  },
  {
    i18nKey: 'section.sortingRoom',
    text: 'Sorting Room',
    value: 'sorting-room',
    items: [
      {
        i18nKey: 'station.sorter',
        text: 'Sorter',
        value: 'sorter',
      },
      {
        i18nKey: 'station.tagger',
        text: 'Tagger',
        value: 'tagger',
      },
      {
        i18nKey: 'station.washer',
        text: 'Washer',
        value: 'washer',
      },
    ],
  },
];

const stationNumbers: IStationNumbers = {
  tagger: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => {
    return {
      text: `Tagger Station #${i}`,
      value: `tagger-station-${i}`,
      i18nKey: 'tagger-station',
    };
  }),
  'shirt-pressing': [1, 2, 3, 4].map((i) => {
    return {
      text: `Shirt Pressing Station #${i}`,
      value: `shirt-pressing-station-${i}`,
      i18nKey: 'shirt-pressing-station',
    };
  }),

  'multi-pressing': [1, 2, 3, 4, 5].map((i) => {
    switch (i) {
      case 1: {
        return {
          text: `Pants Pressing Station`,
          value: `pants-pressing-station`,
          i18nKey: 'pants-pressing-station',
        };
      }
      case 2: {
        return {
          text: `Suits Pressing Station`,
          value: `suits-pressing-station`,
          i18nKey: 'pants-pressing-station',
        };
      }
      case 3: {
        return {
          text: `Tshirt Finisher Station`,
          value: `tshirt-finisher-station`,
          i18nKey: 'tshirt-finisher-station',
        };
      }
      case 4: {
        return {
          text: `6C-Table 1 Station`,
          value: `6c-table-1-station`,
          i18nKey: '6c-table-1-station',
        };
      }
      case 5: {
        return {
          text: `6C-Table 2 Station`,
          value: `6c-table-2-station`,
          i18nKey: '6c-table-2-station',
        };
      }
      default: {
        return null;
      }
    }
  }),
  'collar-cuff-pressing': [1, 2].map((i) => {
    return {
      text: `Collar & Cuff Pressing Station #${i}`,
      value: `collar-cuff-pressing-station-${i}`,
      i18nKey: 'collar-cuff-pressing-station',
    };
  }),
  'pants-topper': [1].map((i) => {
    return {
      text: `Pants Topper Station #${i}`,
      value: `pants-topper-station-${i}`,
      i18nKey: 'pants-topper-station',
    };
  }),
  folding: [1, 2, 3, 4, 5, 6].map((i) => {
    return {
      text: `Folding Station #${i}`,
      value: `folding-station-${i}`,
      i18nKey: 'folding-station',
    };
  }),
  'folding-linen': [1, 2, 3, 4, 5, 6].map((i) => {
    return {
      text: `Folding Linen Station #${i}`,
      value: `folding-linen-station-${i}`,
      i18nKey: 'folding-linen-station',
    };
  }),
  sorter: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => {
    return {
      text: `Sorter Station #${i}`,
      value: `sorter-station-${i}`,
      i18nKey: 'sorter-station',
    };
  }),
  'banned-packaging': [1, 2, 3, 4, 5, 6].map((i) => {
    return {
      text: `Banned Packaging Station #${i}`,
      value: `banned-packaging-station-${i}`,
      i18nKey: 'banned-packaging-station',
    };
  }),
  washer: [1, 2, 3, 4].map((i) => {
    return {
      text: `Washer Station #${i}`,
      value: `washer-station-${i}`,
      i18nKey: 'washer-station',
    };
  }),
  'hand-wash': [1, 2].map((i) => {
    return {
      text: `Hand Wash Station #${i}`,
      value: `hand-wash-station-${i}`,
      i18nKey: 'hand-wash-station',
    };
  }),
  scanner: [1, 2, 3, 4].map((i) => {
    return {
      text: `Scanner Station #${i}`,
      value: `scanner-station-${i}`,
      i18nKey: 'scanner-station',
    };
  }),
  grouping: [1, 2, 3, 4].map((i) => {
    return {
      text: `Grouping Station #${i}`,
      value: `grouping-station-${i}`,
      i18nKey: 'grouping-station',
    };
  }),
  racking: [1, 2].map((i) => {
    return {
      text: `Racking Station #${i}`,
      value: `banned-racking-station-${i}`,
      i18nKey: 'racking-station',
    };
  }),
  'quality-control': [1, 2, 3].map((i) => {
    return {
      text: `Quality Control #${i}`,
      value: `quality-control-station-${i}`,
      i18nKey: 'quality-control-station',
    };
  }),
  tailor: [1, 2].map((i) => {
    return {
      text: `Tailor #${i}`,
      value: `tailor-station-${i}`,
      i18nKey: 'tailor-station',
    };
  }),
  spotter: [1, 2].map((i) => {
    return {
      text: `Spotter #${i}`,
      value: `spotter-station-${i}`,
      i18nKey: 'spotter-station',
    };
  }),
};

const SelectStation: React.FC<{} & RouteComponentProps<{ type: 'number' | 'type' | 'section' }>> = ({
  match: {
    params: { type },
    path,
  },
  history,
}) => {
  const { stationType, stationSection, setStationId, setStationType, setStationSection } = useStationStore();
  let itemList: item[] | any = [];
  let title: JSX.Element;
  const selectHandler = (value: string | StationTypes) => {
    switch (type) {
      case 'number': {
        setStationId(value);
        break;
      }
      case 'section': {
        setStationSection(value);
        history.push(`/login/manager/selectStation/type`);
        break;
      }
      case 'type': {
        setStationType(value as StationTypes);

        history.push(`/login/manager/selectStation/number`);
      }
    }
  };

  switch (type) {
    case 'number': {
      // selecting station number
      itemList = stationNumbers[stationType];
      title = <FormattedMessage id="station.selectStation.number" defaultMessage="Select Station Number" />;
      break;
    }
    case 'type': {
      // selecting station number
      stationSections.map((section) => {
        if (section.value === stationSection) {
          itemList = section.items;
        }
        return null;
      });
      title = <FormattedMessage id="station.selectStation.type" defaultMessage="Select Station Type" />;
      break;
    }
    case 'section': {
      itemList = stationSections;
      title = <FormattedMessage id="station.selectStation.section" defaultMessage="Select Station Section" />;

      break;
    }
  }

  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={(props) => <SelectStationItemList items={itemList} title={title} onSelect={selectHandler} type={type} />}
      />
    </Switch>
  );
};

export default withRouter(SelectStation);
