import React from 'react';
import ReactDOM from 'react-dom';
import '../node_modules/normalize.css';
import { App } from './modules/app';
import * as serviceWorker from './serviceWorker';

import './index.css';
import 'react-simple-keyboard/build/css/index.css';

import bugsnagClient from './utils/lib/bugnsag';

const ErrorBoundary = bugsnagClient.getPlugin('react');

// TODO: add fallback component
ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
