import React from 'react';
import { useUiState } from 'stores';
import { Box, CircularProgress } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

const LoaderComponent: React.FC = observer(() => {
  const { isLoading } = useUiState();
  return (
    <>
      {isLoading && (
        <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%" className="loading-icon">
          <CircularProgress />
        </Box>
      )}
    </>
  );
});

export default LoaderComponent;
